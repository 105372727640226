export default {
  IntuitiveTradingData: "Intuitive Trading Data",
  IntuitiveTradingDataText:
    "Get comprehensive and intuitive trading data in real time, including symbols, order types, volumes and etc.",
  OnestopCustomerManagement: "One-stop Customer Management",
  OnestopCustomerManagementText:
    "Customers can open Lay Ray InTrade with one click, start trading anytime, anywhere, and manage investment decisions",
  OneclickJump: "One-click Jump",
  OneclickJumpText:
    "Log in to the User Center and jump to the InTrade trading platform with one click to start trading in the cloud at any time.",
  ProfessionalOperationInterface: "Professional Operation Interface",
  ProfessionalOperationInterfaceText:
    "Experience the personalized, full-featured yet user friendly professional operation interface.",
  CuttingedgeTechnology: "Cutting-edge Technology",
  CuttingedgeTechnologyText:
    "Start trading immediately wherever you are with the support of cutting-edge technology provided by cloud and top-notch network technology, and access to global backbone network lines.",
  StabilityandSpeedInTrade: "Stability and Speed",
  StabilityandSpeedInTradeText:
    "Complete order within 0.05 seconds and provide you with millisecond order execution speed that has direct access to the world's top exchanges to allow you to make orders anytime, anywhere.",
  CrossdeviceTrading: "Cross-device Trading",
  CrossdeviceTradingText:
    "Seize every opportunity with the supports of web and mobile version of cloud-based trading system that allows you to trade on any device anytime, anywhere.",
  Personalisation: "Personalisation",
  PersonalisationText:
    "Supports multiple custom settings for system and chart displays, and offers a variety of professional drawing tools and language versions to meet customer needs in each country and region.",
  SecureTransactions: "Secure Transactions",
  SecureTransactionsText:
    "The system's distributed, off-site server architecture effectively prevents failures and keeps customers' business online 24/7 with 99.999% availability.",
  IndepthTradingAnalysis: "In-depth Trading Analysis",
  IndepthTradingAnalysisText:
    "The more accurate the analysis, the wiser the decision is",
  MultidimensionalVarietyAnalysis: "Multi-dimensional Variety Analysis",
  MultidimensionalVarietyAnalysisText:
    "Provides real-time market depth and accurate trend charts to help you conquer the US stocks, Hong Kong stocks and futures markets.",
  TopInformation: "Top Information",
  TopInformationText:
    "Access to rich and comprehensive industry information, professional market interpretation, grasp the pulse of the market.",
  EverythingatYourFingertips: "Everything at Your Fingertips",
  EverythingatYourFingertipsText:
    "With Lay Ray Intrade Mobile, you can view account information at any time, track market dynamics, and keep track of any investment opportunity.",
  RealTimePriceUpdates: "Real-Time Price Updates",
  RealTimePriceUpdatesText:
    "Connect to international markets and update prices in real time, making it easy to see US and Hong Kong stocks, futures and other products of interest.",
  UserfriendlyInterface: "User-friendly Interface",
  UserfriendlyInterfaceText:
    "The clear and simple software interface design makes it easy to get started, whether you're viewing account information or trading.",
  ManageAccountinJustaClick: "Manage Account in Just a Click",
  ManageAccountinJustaClickText:
    "Full-featured handheld cloud trading software for managing all accounts at the click of a button.",
  AGlobalViewoftheMarket: "A Global View of the Market",
  AGlobalViewoftheMarketText:
    "Integrate authoritative news from global securities, futures, and more, and open Lay Ray InTrade to get a quick overview of the market.",
  CloudBasedTrading: "Cloud-Based Trading",
  CloudBasedTradingText:
    "Trade on the Lay Ray InTrade website version without downloading the trading software, constantly optimize and upgrade your system to ensure you a reliable trading experience.",
  Varietyrichchartsvisualanalytics: "Variety- rich charts, visual analytics",
  VarietyrichchartsvisualanalyticsText: "InTrade",
  Advancedtechnologypowerfulperformance:
    "Advanced technology, powerful performance",
  AdvancedtechnologypowerfulperformanceText:
    "Lay Ray InTrade has powerful system performance that includes good system compatibility that can be used on any browser.",
  TradingViewDsc:
    "Delivering numerous analysis charts, track market conditions, and research expert trading opinion to empower trades.",
  TradingPlatformTradingViewTitle:
    "A Trading Community that Brings Millions of Professional Investors Together",
  TradingPlatformTraders: "Traders",
  TradingPlatformTradingIdeas: "Trading Ideas",
  TradingPlatformChartsCreated: "Charts Created",
  TradingPlatformFulfillingAllYourTradingNeeds:
    "Fulfilling All Your Trading Needs",
  TradingPlatformIndepthAnalysisToolstoTackletheMarket:
    "In-depth Analysis Tools to Tackle the Market",
  TradingPlatformIndepthAnalysisToolstoTackletheMarketText:
    "Access more than 50 smart drawing tools, 12 customizable chart types, 100+ technical indicators, and in-depth market analysis tool sets including volume distribution (PV).",
  TradingPlatformUpperEnd:
    "Upper End Pine Scripts for Better Trading Strategies",
  TradingPlatformUpperEndText:
    "Create your own pointers or trading programs with built-in Pine scripts. With “HTML5 Canvas” technology, you can trade on our platform with the best performance, flexibility, and the perfect chart overview on any device.",
  TradingPlatformEfficaciousMarketFilter: "Efficacious Market Filter",
  TradingPlatformEfficaciousMarketFilterText:
    "Based on your settings, the best trades are automatically selected and displayed accurately in real time which greatly save you the trouble of manually analyzing the market.",
  TradingPlatformStayAbreastoftheMarket: "Stay Abreast of the Market",
  TradingPlatformStayAbreastoftheMarketText:
    "Set up to 12 different alerts on indicators, strategies and drawing tools. Never miss any trading opportunities with these reliable push notifications backed by our powerful server.",
  TradingPlatformImmenseTechnicalAnalysis: "Immense Technical Analysis",
  TradingPlatformImmenseTechnicalAnalysisText:
    "Explore millions of market analysis from traders across 100+ countries and regions. In addition, you can share your technical analysis indicators and strategies to shape your trading style and journey.",
  TradingPlatformHighlyUserfriendlyInterface: "Highly User-friendly Interface",
  TradingPlatformHighlyUserfriendlyInterfaceText:
    "All rounded access to market depth, trade history and additional order options, allowing you to set your desired stop-profit and stop-loss positions based on account balances.",
  TPTVGlobalMarketDataCoverage: "Global Market Data Coverage",
  TPTVGlobalMarketDataCoverageText:
    "TradingView is strongly linked to hundreds of data sources, directly accessing real-time data from more than 50 stock exchanges around the world. Obtain financial and current hotspot information.",
  TPTVComprehensiveTradingTools: "Comprehensive Trading Tools",
  TPTVComprehensiveTradingToolsText:
    "Swiftly access a watchlist of commodity codes, lists of popular trading products and discover other advantageous functions.",
  TPTV10MillionTraders: "10 Million+ Traders",
  TPTV10MillionTradersText:
    "Whether you are a novice or a professional trader, you can explore investment ideas and make continuous progress in this huge community.",
  TPTVNewTradingPerspective: "New Trading Perspective",
  TPTVNewTradingPerspectiveText:
    "Thousands of insights and live broadcasts released every day, delivering global market analysis.",
  TPTVSupportsMultipleLanguages: "Supports Multiple Languages",
  TPTVSupportsMultipleLanguagesText:
    "Available in more than 30 fully localized languages.",
  TPTVFrequentlyUpdatedCharts: "Frequently Updated Charts",
  TPTVFrequentlyUpdatedChartsText:
    "Charts are updated multiple times per second, based on the latest quotes. Get active results even when loading a large amount of data.",
  TPTVAccessibleAnytimeAnywhere: "Accessible Anytime, Anywhere",
  TPTVAccessibleAnytimeAnywhereText:
    "The charts are viable on any operating system, device and browser. ",
  TPTVConnectto: "Connect to Lay Ray",
  TPTVConnecttoText:
    "Seamless signal transmission backed by no other networks or hardware but WAN",
  TPTVRegister: "Register",
  TPTVAVarietyofSteadfastTradingSoftware:
    "A Variety of Steadfast Trading Software",
  TPTVAVarietyofSteadfastTradingSoftwareText:
    "Kickstart Your Trading Journey with Multiple Popular Softwares",
  TPTVDooFinancialInTradeText:
    " Professional Trading Platform for the Mobile Centrics ",
  TPTVMetaTrader4Text: "Most Popular Trading Platform",
  TPTVMetaTrader5Text: "A Multi-asset Platform",
  TPmetatraderMostPopularTradingPlatform: "Most Popular Trading Platform",
  TPmetatraderMostPopularTradingPlatformText: "Providing a first-class trading experience by incorporating advanced technical analysis and an all-rounded trading system.",
  TPmetatraderPioneeringTradingSystem: "Pioneering Trading System",
  TPmetatraderPioneeringTradingSystemText: "Distinctively integrates and executes trades of various market types, including pending orders, stop orders, and trailing stop.",
  TPmetatraderPowerfulAnalysisTools: "Powerful Analysis Tools",
  TPmetatraderPowerfulAnalysisToolsText: "Analyse price volatility and strategize your trades ahead with nearly 3,000 indicators, 24 analysis objects, and customizable exclusive dynamic charts.",
  TPmetatraderExpeditiousTradingExperience: "Expeditious Trading Experience",
  TPmetatraderExpeditiousTradingExperienceText: "ake advantage of automated trading and execute your trading orders with minimal delay via Lay Ray MT4's dedicated VPS trading server.",
  TPmetatraderTradewithEAonMT4: "Trade with EA on MT4",
  TPmetatraderTradewithEAonMT4Text: "Trade effortlessly with Expert Advisors (EAs) that personalized custom trading strategy and automatically executes trades",
  TPmetatraderHighspeedExecution: "High-speed Execution",
  TPmetatraderHighspeedExecutionText: "Executing trades swiftly and efficiently by analyzing market trends. Also identifies market opportunities automatically to buy and sell. ",
  TPmetatraderOperatingRoundtheClock: "Operating Round the Clock",
  TPmetatraderOperatingRoundtheClockText: "Stays active 24 hours a day, while automatically executing trades based on your trading conditions to yield financial gains. ",
  TPmetatraderBackTestingofHistoricQuotes: "Back Testing of Historic Quotes",
  TPmetatraderBackTestingofHistoricQuotesText: "Users can evaluate and adjust their trading strategies through back testing and view the historical profitability statistics to make the best trading decisions. ",
  TPmetatraderExtensiveFeatures: "Extensive Features",
  TPmetatraderExtensiveFeaturesText: "Supports EA automated trading and multi-account management system, incorporating a high degree of versatility and customization.  Available in multiple languages. ",
  TPmetatraderMQL5sAlgorithmicTradingStrategies : " MQL5's Algorithmic Trading Strategies ",
  TPmetatraderMQL5sAlgorithmicTradingStrategiesText: "Empowering professional traders' execution efficiency with extensive functions, programmed language and built-in technical indicators for quotation analysis. ",
  TPmetatraderMetaTrader4DesktopVersion: "MetaTrader 4 Desktop Version",
  TPmetatraderMetaTrader4DesktopVersionText: "Enjoy the widest range of trading opportunities by downloading Lay Ray MT4 Desktop Version . Access the most vigorous and favorable technical analysis and trading tools in the market.",
  TPmetatraderMetaTrader4Mobile: "MetaTrader 4 Mobile",
  TPmetatraderMetaTrader4MobileText: "Seize market opportunities around the clock and trade anytime, anywhere with Lay Ray MT4 mobile.",
  TPmetatraderMT4VSMT5: "MT4 VS MT5",
  TPmetatraderMT4VSMT5Text: "Both trading platforms consolidate different functions and features that cater to traders of various types.",
  TPmetatraderPartialorderexecutionpolicy: "Partial order execution policy",
  TPmetatraderPendingOrderType: "Pending Order Type",
  TPmetatraderMarketDepth: "Market Depth",
  TPmetatraderMore: "More",
  TPmetatraderAVarietyofSteadfastTradingSoftware: "A Variety of Steadfast Trading Software",
  TPmetatraderAVarietyofSteadfastTradingSoftwareText: "Kickstart Your Trading Journey with Multiple Popular Softwares",
  TPmetatrader5Title: "Integrating a diverse range of trading instruments including forex, stocks, futures in the global financial market through a single platform.",
  TPmetatrader5CuttingedgeTradingSoftware: "Cutting-edge Trading Software",
  TPmetatrader5CuttingedgeTradingSoftwareText: "Trade various products in multiple markets via a full-fledged trading software with efficacious functions and tools.",
  TPmetatrader5ComprehensiveTradingSystem: "Comprehensive Trading System",
  TPmetatrader5ComprehensiveTradingSystemText: "Smoothly execute each and every trade through one account that supports all order types and execution modes to avoid missing any buy or sell opportunities.",
  TPmetatrader5QuoteSpecializedChartAnalysis: "Quote Specialized Chart Analysis",
  TPmetatrader5QuoteSpecializedChartAnalysisText: "Explore and create custom indicators or access up to 80 technical indicators and analysis tools in 21 timeframes. You can also employ 100+ charts to identify the trend of various trading products.",
  TPmetatrader5TechnicalAnalysis: "Technical Analysis",
  TPmetatrader5TechnicalAnalysisText: "Integrates a complete set of analysis methods, including 38 technical indicators and 44 graphical objects, comprehensive market analysis, and forecast the price trends of your interest.",
  TPmetatrader5FundamentalAnalysis: "Fundamental Analysis",
  TPmetatrader5FundamentalAnalysisText: "Stay up to date and strategise your trades in advance with MT5's built-in tools. Gain direct updates on financial news, economic calendar, and real-time quotes sourced from international news organizations.",
  TPmetatrader5CustomizableTradingAlert: "Customizable Trading Alert",
  TPmetatrader5CustomizableTradingAlertText: "Stay in control and seize every opportunity by setting your preferred conditions for trade alert. Receive notifications of important trading events via email, mobile phone reminders, etc.",
  TPmetatrader5TradewithEAoMT5: "Trade with EA on MT5",
  TPmetatrader5TradewithEAoMT5Text: "Trade effortlessly with Expert Advisors (EAs) that personalized custom trading strategy and automatically executes trades",
  TPmetatrader5Intelligent: "Intelligent",
  TPmetatrader5IntelligentText: "Strictly abides to the programming algorithm, objective and uninterrupted large number of computing operations.",
  TPmetatrader5Instantaneous: "Instantaneous",
  TPmetatrader5InstantaneousText: " Seize every trading opportunity with accurate signals via real-time processing of quotes for a large number of currencies, stocks and other trading products. ",
  TPmetatrader5OperatingRoundtheClock: "Operating Round the Clock",
  TPmetatrader5OperatingRoundtheClockText: " Stays active 24 hours a day, while automatically analyzing market conditions and executing trading operations around the clock. ",
  TPmetatrader5MetaTrader5DesktopVersion: "MetaTrader 5 Desktop Version",
  TPmetatrader5MetaTrader5DesktopVersionText: "Download Lay Ray MT5 desktop version now and trade currency pairs, stocks, futures and other financial products. Seize every trading opportunity with various trading functions, comprehensive technical analysis, copy trading, and EA automatic trading functions.",
  TPmetatrader5MetaTrader5Mobile: "MetaTrader 5 Mobile",
  TPmetatrader5MetaTrader5MobileText: "Enjoy global investment by trading various currency pairs, stocks and other securities anytime, anywhere with LayRay Prime MT5 mobile.",
  
};
