import zh2 from "./zh2.js";
export default {
  ...zh2,
  home: "首页",
  products: "产品",
  securities: "股票",
  futures: "期货",
  cfds: "差价合约",
  tools: "工具",
  tradingPlatform: "交易平台",
  tradingTools: "交易工具",
  vps: "VPS 交易专用服务器",
  trading: "交易",
  pricingOverview: "定价概览",
  commission: "交易佣金",
  generalFee: "一般费用",
  marginRate: "保证金信息",
  tradingService: "交易服务",
  funding: "资金存取",
  partnership: "合作伙伴",
  exchangePartners: "合作交易所",
  introducingBroker: "介绍经纪商",
  whiteLabelPartner: "白标经纪商",
  aboutUs: "关于我们",
  ourStory: "公司简介",
  privacyPolicy: "隐私政策",
  termsOfService: "使用条款",
  contactUs: "联系我们",
  helpCenter: "帮助中心",
  dooEntitiesNotice: "LayRay 实体公告",
  RiskDisclosure: "风险披露",
  RiskDisclosureContent1:
    "由于不可预测的市场变动，证券、期货、货币、外地证券、差价合约等金融产品的在线交易涉及巨大损失风险，并可能导致超出您初始投资的重大损失。 一项投资的过往表现不代表其未来的表现。 在与我们进行任何交易之前，请确保您完全了解使用相应金融工具进行交易的风险。 如果您不了解此处说明的风险，则应寻求独立专业的意见。此处显示的任何交易符号仅用于说明目的，不构成我们的任何建议或推荐。 对于您的交易造成的任何直接、间接、特殊或者任何后果及附带损失，LayRay 不承担任何责任。",
  BrandandEntityNotice: "品牌和实体公告",
  BrandandEntityNoticeContent1:
    "LayRay  的品牌名称和知识产权。 LayRay 保留对 LayRay 及其相关品牌的所有权利，包括但不限于 LayRay Tech、LayRay Prime、LayRay Consulting、LayRay Clearing、Peter Elish 等。",
  BrandandEntityNoticeContent2:
    "我们在不同司法管辖区拥有受监管和许可的实体，以提供各种各样的金融产品和服务。根据适用的法律和法规，您可以与我们的任何实体进行商业交易。请记住，所选实体意味着您的交易账户、交易活动和资金将受该实体所在司法管辖区的相应监管机构管辖和监管。",
  BrandandEntityNoticeContent3: "公司将会有以下两个选择：",
  BrandandEntityNoticeContent4:
    "(i) 通过适用的相关实体提供有限的访问权限；或者",
  BrandandEntityNoticeContent5: "(ii) 不提供相关服务；",
  BrandandEntityNoticeContent6:
    "我们网站上的信息和服务不适用于某些司法管辖区的个人或公司，包括但不限于阿富汗、阿塞拜疆、波斯尼亚和黑塞哥维那、缅甸、加拿大、刚果、古巴、埃塞俄比亚、圭亚那、海地、香港、印度尼西亚、伊朗、伊拉克、以色列、日本、黎巴嫩、马来西亚、马里、朝鲜、新加坡、斯里兰卡、索马里、苏丹、特立尼达和多巴哥、突尼斯、委内瑞拉、阿拉伯联合酋长国和美国。我们不会向违反当地法律和法规的国家或司法管辖区提供信息和服务。来自上述地区的访客在使用我们的服务前，应确认您的投资行为是否符合您所在国家或司法管辖区的法律法规。我们保留随时更改、修改或停止我们的产品和服务的权利。",
  WebsiteTerms: "网站条款和条件",
  WebsiteTermsContent1:
    "本网站由LayRay Singapore Pte. Ltd.拥有和运营（公司注册号 202104581D）。 通过访问或使用本网站，您同意接受本网站中包含的任何条款和条件、声明、免责声明和通知的约束。 您必须年满 18 岁才能访问或使用我们的网站、产品和服务。 通过访问或使用本网站，您确认您是符合您所在国家/地区当地法律和法规的完整年龄和行为能力的个人。 ",
  WebsiteTermsContent2:
    "本网站提供的信息属于一般性质，其中未包含对任何人的目标、财务需求或情况的考量。",
  WebsiteTermsContent3:
    "本网站提供的信息以及产品和服务不会提供给违反当地法律和/或法规的任何国家或司法管辖区的任何个人或实体。",
  WebsiteTermsContent4:
    "本网站提供的任何评论、声明或数据、材料或第三方材料（“材料”）仅用于说明目的。该材料既不针对任何从事宣传、促销或营销活动的个人或组织，也不应被解释为商业建议、税务建议、交易建议、法律或监管建议、投资建议、任何交易的投资建议、任何形式的承诺或要约。我们会尽量确保所提供信息的准确性和完整性，但我们对材料不作任何陈述或保证，也不对任何损失负责，包括但不限于利润损失、直接或间接损失或因所提供信息的任何不准确和/或不完整而造成的损失。您只能将材料用于个人用途，未经我们同意，不得复制、重新分发任何牌照信息及相关材料。",
  WebsiteTermsContent5:
    "我们可能会对本网站进行不定期修改，恕不另行通知，您继续访问或使用本网站即表示您接受这些修改。 ",
  WebsiteTermsContent6:
    "我们遵守有关使用您个人信息的所有适用法律和法规，更多香港信息，请参阅我们的隐私政策。 ",
  WebsiteTermsContent7:
    "我们在我们的网站上使用 cookies 来根据您的喜好定制我们网站上显示的信息和体验。通过访问本网站，您确认您已阅读并同意上述详细信息，并同意我们使用 cookies 。有关我们使用 cookies 的更多信息，请参阅我们的 cookies 政策。",
  WebsiteTermsContent8:
    "我们严格遵守我们实体经营所在相关司法管辖区的所有适用法律和法规。请确保您的投资符合要求是您的责任，您确认您将承担投资和交易的所有后果。 ",
  WebsiteTermsContent9:
    "如果您有任何问题或意见，或者您对我们在某些司法管辖区提供服务的方式有疑虑，可以通过电子邮件与我们联系。",
  asset: "* 资产保险仅适用 Peter Elish Investments Securities 的客户账户",
  company:
    "本网站由 LayRay Holding Pte. Ltd. 持有和运营（公司注册号码 201930035D）",
  OnestopInvestmentChannel: "一键配置全球资产",
  OnestopInvestmentChannelContent:
    "一个账户投资美股、港股、期货、差价合约<br/>轻松玩转全球金融市场，实现全球化资产配置",
  TradeontheGlobalExchanges: "直通全球顶级交易所",
  TradeontheGlobalExchangesCotent:
    "网罗全球优质资产标的 <br>10,000+全球热门金融产品任你选择<br />",
  homeBanner3: "极具竞争力的定价",
  homeBanner3Content: "港股佣金低至 3 港币，美股佣金低至 0.003 美元/股",
  homeBanner4: "尖端科技支持",
  homeBanner4Content:
    "极速交易响应，50 毫秒内执行 99.5% 订单 <br>超低延时，稳定可靠 <br/>",
  homeBanner5: "亮相美国纳斯达克大屏",
  homeBanner5Content: "与盈透证券建立全披露券商关系",
  ReKnowMore: "查看更多",
  GlobalInvestmentsViaOneAccount: "全球投资 一个账户",
  GlobalInvestmentsViaOneAccountContent:
    "凭借强大的技术实力，LayRay 已成功打入全球金融市场，专业投资者仅需一个账户即可投资全球证券，期货，差价合约等金融产品。",
  InitialCommissionFee: "佣金起始费率为",
  USStockTrading: "美国股票",
  ETFslistedonUSExchanges: "美国上市ETF",
  MutualFundsInvestment: "共同基金",
  AllFee: "佣金、托管费和平台费为",
  StockOptions: "股票期权",
  CommissionFee: "佣金费率低至",
  alot: "每手",
  Forex: "货币",
  Spreadfrom: "指数差价合约",
  PreciousMetals: "贵金属",
  Energy: "能源",
  IndexCFDs: "指数差价合约",
  StockCFDs: "股票差价合约",
  FuturesCFDs: "期货差价合约",
  Licensed: "全球持牌 规管先行",
  LicensedContent:
    "Lay Ray 作为一个全牌照互联网券商品牌，其各地经营主体分别持有美国、英国等地的金融监管牌照，它们分别受到美国证监会（SEC）、金融监管局（FINRA）和英国金融行为监管局（FCA）等监管主体的严格监管。",
  Experience: "尖端科技 极致体验",
  ExperienceContent:
    "作为一家以金融科技为核心的互联网券商，LayRay 始终致力于技术研发与科技创新，不断丰富功能、提升执行力，为客户提供极致的交易体验。",
  UserCenter:
    "全能用户中心<span>支持网页端、移动端注册，多设备操作方便快捷，打造个性化操作界面，随时随地体验强大交易系统。</span>",
  DataSecurity:
    "严格数据安保<span>全站采用 SSL 256 位加密传输，数据库内外网隔离，确保客户数据、交易数据全程严格加密、安全传输。</span>",
  HardwareSupport:
    "强大硬件配套<span>与 Equinix® 合作将数据中心节点布局在伦敦（LD5）、纽约(NY5）、香港（HK2）、新加坡（SG1）等多个金融中心城市，联通全球 。</span>",
  MultiplePlatforms: "多种平台 丰富选择 ",
  MultiplePlatformsContent:
    "Lay Ray 提供多种国际认可且可靠的交易平台，丰富的工具和功能助您识别和把握交易机会，提升您的交易体验。",
  InTradeContent:
    "以移动端为核心的新一代专业交易平台 ，支持全球数万个美股、港股和期货产品等交易，一键连接环球投资。",
  KnowMore: "更多信息",
  TradingViewContent:
    "强大的云端交易平台，为您提供即时图表、深度分析，观摩全球顶尖投资者的交易思路。",
  MetaTrader4Content:
    "全球最热门的交易平台，支持 EA 自动化交易及多账户管理系统，方便用户使用，同时兼具高度通用性。",
  MetaTrader5Content:
    "新一代的多元资产交易平台，具备专业的技术分析工具及比 MT4 更强大的性能，操作灵活且高度可靠。",
  SecurityofFunds: "资金保障 安全无忧",
  SecurityofFundsContent:
    "确保客户资产安全是我们的核心职责之一，也是我们永远不变的价值追求。 ",
  ExtensivelyRegulated: "多重监管",
  ExtensivelyRegulatedContent:
    "我们客户的资产和交易受到 LayRay 位于全球各个国家/地区的金融实体的相应金融监管机构的严格监管，如美国证券交易委员会(SEC) 、金融业监管局 (FINRA)、英国金融行为监管局 (FCA)、 毛里求斯金融服务委员会（MFSC）和瓦努阿图金融服务委员（VFSC）。适用的相关法律规定将取决于客户开户的金融实体。",
  AssetsInsurance: "资产保险",
  AssetsInsuranceContent:
    "在 LayRay 的美国金融实体开设的账户将受到美国证券投资者保护公司 (SIPC) 的保护，保险金额最高可达 500,000 美元（现金限额为 250,000 美元）。*",
  FundsSegregation: "资金隔离",
  FundsSegregationContent:
    "客户资产与我们金融实体的运营资金独立隔离，分别存放在渣打银行、贝森银行、巴克莱银行，账户严格按照国际银行的财务规定进行管理，有效地保护我们客户在指定账户中的资金。",
  GlobalPresence: "全球业务 生态共建",
  GlobalPresenceContent:
    "Lay Ray 是 互联网券商品牌， LayRay 在达拉斯、伦敦、新加坡、香港、迪拜、吉隆坡设立了全球运营办事处，通过全球化的布局，为高净值客户提供一站式全球投资和金融交易服务。",
  IntroducingBrokers: "介绍经纪人",
  InstitutionalPartners: "机构合作伙伴",
  Employeesin11cities: "来自 11 个城市的行业精英",
  securitiesContent:
    "通过一个 Lay Ray 账户交易 10,000+<br/> 美股、港股产品，分享全球顶尖上市公司的发展红利。",
  WhatAreSecurities: "股票产品介绍",
  Securitiesopportunities:
    "股票交易是最流行的投资方式之一，LayRay 提供强大的股票交易工具以及极具竞争力的股票交易佣金助您进入多个市场，把握全球机遇。",
  USStocks: "美股",
  USStocksContent1:
    "美股即美国股市，是世界上最发达的股票市场，无论是股票发行及交易品种的数量还是股票市场容量及市场发育程度，在世界上都是首屈一指的。",
  USStocksContent2: "投资美股的好处：",
  USStocksContent3: "全球市值最大且流通量最高的股票市场",
  USStocksContent4: "全球顶尖公司汇集，优质股票多",
  USStocksContent5: "估值合理，更注重股东回报",
  USStocksContent6: "交易手段多元化",
  USStocksContent7:
    "Lay Ray 作为新一代互联网券商，提供丰富的美股交易品类、极具竞争力的美股佣金以及全流程电子化服务。交易者可通过 Lay Ray 交易在 NYSE 纽约证券交易所和 NASDAQ 纳斯达克证券股所的全部股票。",
  HongKongStocks: "港股",
  HongKongStocksCotent1:
    "港股是指在香港联合交易所上市的股票。香港作为亚洲最大的国际金融中心，是亚洲主要上市及融资的市场。",
  HongKongStocksCotent2: "投资港股的好处",
  HongKongStocksCotent3: "监管严格，资金安全更有保障",
  HongKongStocksCotent4: "优质公司多，升值潜力大",
  HongKongStocksCotent5: "A、H 股价差存在套利空间",
  HongKongStocksCotent6: "打新中签率普遍比 A 股要高",
  HongKongStocksCotent7:
    "相较于内地来说，香港市场更加成熟、理性，对世界的行情反应更加灵敏。LayRay 作为新一代互联网券商，提供丰富的港股交易品种，多样化的投资组合给予投资者更多的选择。",
  SecureExperience: "安全流畅的交易体验",
  DiverseInvestmentCategories: "投资品类丰富",
  DiverseInvestmentCategoriesContent:
    "只需一个账户，即可投资股票 、ETF、 共同基金 、股票期权等多种交易品种。",
  CompetitiveTradingCosts: "极具竞争力的交易成本",
  CompetitiveTradingCostsContent:
    "提供行业内极具竞争力的交易成本,帮助投资者获得更大的利润空间。",
  TradinginAnytime: "随时随地，灵活交易",
  Tradefingertips:
    "随时随地在移动端上进行交易，享受指尖上的全球投资，资金永不眠。",
  EfficientTrading: "极速交易",
  EfficientTradingContent:
    "数据中心部署于 Equinix，99.5% 的交易订单在 50 毫秒被执行。",
  InternationalFinancialRegulation: "多重国际金融监管",
  TheoperatingKingdom:
    "受美国SEC、FINRA及英国FCA等国际监管主体的严格监管，确保客户资金受到严格保护。",
  ProfessionalSupport: "24*7*365 专业支援",
  With500:
    "LayRay 拥有 500+ 行业精英，为投资者提供全年 365 天无休止的支援服务。",
  DirectAccess: "直通全球证券交易所",
  OneAccount:
    "一个账户直通全球证券交易所，畅享与全球金融市场无缝对接的快速交易。",

  NewYorkStockExchange: "纽约证券交易所",
  NasdaqStockExchange: "纳斯达克证券交易所",
  HongKongStockExchange: "香港证券交易所",
  MultiplePlatforms: "多种平台 丰富选择",
  tradingsoftwaredes: "多款热门软件随心挑选，开启专业、便捷的交易体验 ",
  InTrade: "以移动端为核心的新一代交易平台",
  TradingView: "新一代 HTML5 交易平台",
  MetaTrader4: "全球最热门的交易平台",
  MetaTrader5: "新一代的多元资产交易平台",
  productsharesheightitle: "关于股票的更多信息",
  USStockTradingHours: "美股交易时间",
  HongKongStockTradingHours: "港股交易时间",
  USEasternTime: "美东时间",
  BeijingTime1: "北京时间（夏令时）",
  BeijingTime2: "北京时间（冬令时）",
  Pretrade: "盘前",
  Intraday: "盘中",
  Afterhours: "盘后",
  TradingTakes: "周一到周五进行交易，交易时间如下：",
  MorningPoolingSession: "【早盘集合竞价时段】",
  AM: "上午",
  ContinuousTradingSession: "【持续交易时段】即正常交易时段",
  Morning: "上午",
  Afternoon: "下午",
  ClosingSession: "【收市竞价时段】",
  ClosingSessionLabel:
    "证券市场的正常交易日的收市时间，已由下午4时正改为下午4时8分至4时10分之间随时随机收市，半日市的收市时间则已由中午12时正改为中午12时8分至12时10分之间随时随机收市 ",
  FAQ: "常见问题",
  faqTitle1: "美股最小交易单位",
  faqText1: "与A股和港股不同，美股没有“手”的概念，最小交易单位是1股。 ",
  faqTitle2: "美股有涨跌幅限制吗？ ",
  faqText2: "不同于A股，美股没有单日涨跌幅限制，投资者需注意控制风险。 ",
  faqTitle3: "港股最小交易单位",
  faqText3:
    "港股最小交易单位为“1 手”，每手所含的股票数量根据股票价格来决定，1 手可能是 100 股、500 股、1000 股和 2000 股不等。例如腾讯控股每手是 100 股，比亚迪每手 500 股。不足一手的持仓数量即为“碎股”。比如，腾讯控股，一手为 100 股，如果持有 150 股腾讯，那么 50 股就成了碎股。一般公司送红股或者供股的时候会产生碎股。 ",
  faqTitle4: "港股有涨跌幅限制吗？ ",
  faqText4: "不同于A股，港股没有单日涨跌幅限制，投资者需注意控制风险。 ",
  ExploreAllDooFinancialProducts: "探索 Lay Ray 所有产品",
  Securities: "股票",
  SecuritiesText:
    "在全球主流的交易所交易 10,000 多只美股和港股产品，分享顶尖上市公司的发展红利，享受极具竞争力的股票佣金。",
  Futures: "期货",
  FuturesText:
    "提供来自交易所的丰富期货产品，包括纳斯达克指数、标普500 等环球股指，以及黄金、白银及原油等大宗商品期货 ，任您挑选。",
  CFDs: "差价合约",
  CFDsText:
    "极速交易逾 10,000 种差价合约产品，包括股票、指数、期货等，享受低至 0.1 Pips 的极低点差和佣金，灵活交易，做多或做空都有机会盈利。 ",
  FuturesBannerText:
    "连通全球主流期货交易市场，涵盖股票指数、商品、<br>能源、股票、利率、农产品，享受交易全球期货的乐趣。<br>",
  WhatAreFutures: "期货产品介绍",
  WhatAreFuturesText:
    "期货作为最重要的管理价格风险的衍生工具，是买卖双方约定在指定日期时间，以预先确认的价格及其他交易条件，进行买入或卖出指定标的物的标准化合约。",
  SecureandSeamlessTradingExperienceText6:
    "LayRay 拥有 500+ 行业精英，为投资者提供全年 365 天无休止的支援服务。",
  SecureandSeamlessTradingExperienceTitle6: "24*7*365 专业支援",
  SecureandSeamlessTradingExperienceText5:
    "受美国SEC、FINRA及英国FCA等国际监管主体的严格监管，确保客户资金受到严格保护。",
  SecureandSeamlessTradingExperienceTitle5: "多重国际金融监管",
  SecureandSeamlessTradingExperienceText4:
    "数据中心部署于 Equinix，99.5% 的交易订单在 50 毫秒被执行。",
  SecureandSeamlessTradingExperienceTitle4: "极速交易",
  SecureandSeamlessTradingExperienceText3:
    "随时随地在移动端上进行交易，享受指尖上的全球投资，资金永不眠。",
  SecureandSeamlessTradingExperienceTitle3: "随时随地，灵活交易",
  SecureandSeamlessTradingExperienceText2:
    "提供行业内极具竞争力的交易成本,帮助投资者获得更大的利润空间。",
  SecureandSeamlessTradingExperienceTitle2: "极具竞争力的交易成本",
  SecureandSeamlessTradingExperienceText1:
    "极速交易来自全球主流交易所的 200+ 期货产品，玩转全球期货。",
  SecureandSeamlessTradingExperienceTitle1: "全球期货品类",
  SecureandSeamlessTradingExperience: "安全流畅的交易体验",
  BenefitsofInvestingonFuturesText5:
    "Lay Ray 可为广大投资者提供全球期货产品，包括股票指数、商品、能源、股票、利率、农产品期货，连通全球主流期货交易市场，直接交易来自 CME Group 芝加哥商品交易所、 CBOE 芝加哥期权交易所、 HKFE 香港期货交易所、SGX 新加坡交易所的多种期货。",
  BenefitsofInvestingonFuturesText4:
    "可以与股票及其他交易产品进行对冲，降低风险",
  BenefitsofInvestingonFuturesText3: "交易灵活，支持 T+0 交易制度",
  BenefitsofInvestingonFuturesText2:
    "保证金交易，充分利用杠杆，提高资金利用效率",
  BenefitsofInvestingonFuturesText1: "可以双向交易，既可看涨也可看跌",
  BenefitsofInvestingonFutures: "投资期货的好处",
  cfdstab11: "杠杆",
  cfdstab12: "融资融券有限制，杠杆倍数一般不超过 3 倍",
  cfdstab13: "个股 CFD 及个股期货 CFD 分別是 10 倍及 20 倍 *",
  cfdstab14: "一般 8 至 12 倍",
  cfdstab15: "指数及商品的期货 CFD 最高 200 倍 *",
  cfdstab21: "保证金要求 ",
  cfdstab22: "高",
  cfdstab23: "低",
  cfdstab31: "交易时间",
  cfdstab32: "每周 5 天，美股及港股每天分別为 6.5 小时及 5.5 小时",
  cfdstab33: "每周 5 天，每天最长约 6.5 小时 *",
  cfdstab34: "每周 5 天，单一交易所电子交易每天最长 23 小时",
  cfdstab35: "每周 5 天，全天最长 23 小时 *",
  cfdstab41: "交易买卖的产品 受地域及账户所限",
  cfdstab51: "移仓换月要求",
  cfdstab61: "交易成本",
  cfdstab62:
    "每笔交易被收取固定比率的佣金，交易所及监管当局的征费、税费，一般没有最低手续费",
  cfdstab63: "低资金成本，以及低或无手续费 *",
  cfdstab71: "平台",
  cfdstab72: "通过经纪商或银行提供的交易软件进行买卖",
  cfdstab73:
    "可选择 MT4、MT5、InTrade、TradingView、FIX API 4.4 等多个专业交易平台进行买卖 *",
  FuturesCFD: "期货差价合约（CFD）",
  SecuritiesCFD: "股票差价合约（CFD）",
  CFDTradingAdvantagesComparisonText:
    "作为一种高杠杆的金融衍生品，差价合约采取的是保证金交易制度，高杠杆是其主要优势，让您能够利用少量初始本金占有更多市场份额，获得更多参与全球市场的机会。",
  CFDTradingAdvantagesComparison: "差价合约交易优势对比",
  Leverageof500Text:
    "以小额资金开始交易，自由选用高至 500 倍的杠杆率，将回报更大化。",
  Leverageof500: "500倍杠杆率",
  UltraLowSpreadsText: "点差低至 0.1 Pips，力求将交易成本降至最低。",
  UltraLowSpreads: "超低点差",
  Over10000TradingInstrumentsText:
    "全面接触超过 10,000 个产品的差价合约，捕捉无尽交易机会。",
  Over10000TradingInstruments: "逾 10,000 个交易品种",
  BenefitsofInvestinginCFDsText5:
    "Lay Ray 上有逾 10,000 种差价合约产品，包括股票、指数、期货等，投资者能够以低至 0.1 Pips 的极低点差灵活交易，享受一流的交易体验。",
  BenefitsofInvestinginCFDsText4:
    "无需实际拥有资产标的，交易灵活，节省各种交易征费",
  BenefitsofInvestinginCFDsText3: "可以多空双向交易，都有机会盈利",
  BenefitsofInvestinginCFDsText2: "保证金交易，充分利用杠杆，提高资金利用效率",
  BenefitsofInvestinginCFDsText1:
    "交易范围广泛，无需访问多种平台，即可交易不同的金融市场",
  BenefitsofInvestinginCFDs: "投资差价合约的好处",
  WhatAreCFDsTitle:
    "差价合约 （Contract for Difference） 是一种合约交易，它不涉及任何实物商品或证券交换，而是仅以结算价与合约价的差额作现金结算的一种交易方式，转而由经纪商的专业团队进行对冲、风险控制，使用户能够便利地交易，不用考虑过多的监管规则，如 Short Sell 卖空。",
  WhatAreCFDs: "差价合约产品介绍",
  cfdsTitle:
    "交易全球 10,000+ 差价合约产品，享受极具竞争力的点差和极速的交易执行。",
  ForMoreInfoText4:
    "期货合约的交易以保证金的形式进行，用户只需要合约价值一定百分比（保证金比例低于50%，通常为10%左右）的初始保证金即可进行交易，交易所每日对于用户持仓合约进行一次结算，对于保证金数额不满足要求的用户进行保证金追缴，期货商可能会对于隔夜合约收取更高保证金。",
  ForMoreInfoTitle4: "期货的交易形式",
  ForMoreInfoText3:
    "期货品种涨跌幅限制范围较大，部分合约不设涨跌幅限制，请投资人了解并注意控制风险。",
  ForMoreInfoTitle3: "期货涨跌幅",
  ForMoreInfoText2:
    "期货的交易单位与股票不同，是以“合约”为交易单位，期货的最小交易单位为一张标准合约，一张合约通常称为一手，不同期货品种的标准合约规格不同。",
  ForMoreInfoTitle2: "期货最小交易单位",
  ForMoreInfoText1:
    "按照交易所的规定，每个期货品种交易时间不同，详情请在LayRay 交易软件中查看，具体以交易所公告为准。",
  ForMoreInfoTitle1: "期货交易时间",
  ForMoreInformationAboutFutures: "关于期货的更多信息",
  HongKongFuturesExchange: "香港期货交易所",
  EuropeanFuturesExchange: "欧洲期货交易所",
  CMEGroup: "芝加哥商品交易所集团",
  ChicagoBoardofTrade: "芝加哥期货交易所",
  SingaporeExchange: "新加坡交易所",
  NewYorkMercantileExchange: "纽约商业交易所",
  NewYorkCommodityExchange: "纽约商品交易所",
  DirectAccesstoGlobalFuturesExchangesText:
    "已实现打通全球期货交易市场的交易账户，仅需一个账户即可玩转全球期货。",
  DirectAccesstoGlobalFuturesExchanges: "直通全球期货交易所",
  WhatismarginText:
    "保证金可以视作为维持开仓部位所需的实际押金。这并非一项费用或交易成本，只是将账户净值的一部份拨出及分配为保证金存款。",
  Whatismargin: "什么是保证金？",
  WhatisovernightinterestText: "隔夜利息是持仓过夜所支付或赚取的利息。",
  Whatisovernightinterest: "什么是隔夜利息？",
  WhatarethecostsofCFDsText:
    "Lay Ray 是通过点差进行获利，即买入、卖出价的价差。另外，如果您在美东时间下午5点后持仓隔夜，可能会被收取隔夜利息。",
  WhatarethecostsofCFDs: "差价合约有什么成本？",
  WhatisCFDText:
    "差价合约是指买卖双方订立合同，通过结算价与合约价的差额作结算的交易方式， 并不涉及直接对证券的交易。",
  WhatisCFD: "什么是差价合约？",
  ForMoreInformationAboutCFDs: "关于差价合约的更多信息",
  MultiplePlatformsCFDsContent:
    "多款热门软件随心挑选，开启专业、便捷的交易体验",
  FuturesCFDsLabel: "广泛交易商品期货和股指期货，从市场波动中获益",
  StockCFDsLabel: "支持Facebook、阿里巴巴等 300+ 明星美港股",
  IndexCFDsLabel: "提供全球各地最受欢迎的股票指数交易",
  EnergyLabel: "进入能源市场交易原油等，使您的投资组合多样化",
  PreciousMetalsLabel: "黄金、白银等贵金属投资组合，助您实现资产增值",
  ForexLabel: "USD/JPY、EUR/CAD、GBP/USD 等热门货币对",
  CFDProductsTime: "Lay Ray 10,000+ 多元差价合约产品",
  CFDProducts: "Lay Ray 10,000+ 多元差价合约产品",
  AccordingtostandardofDooFinancial: "*根据 Lay Ray 标准",
  tradingplatformintradeLabel:
    "海量交易产品，支持全球数万个美股、港股 和期货产品等交易，一键连接环球投资。",
  tradingplatformintrade: "新一代专业交易平台",
  AMobilecentric: "以移动端为核心的",
  AbundantinInvestmentTypesText:
    "覆盖美股港股、ETF、共同基金、股票期权及全球期货产品，为您提供更多获利选择。",
  AbundantinInvestmentTypes: "投资品种丰富",
  HighSpeedOnlineAccountRegistrationText:
    "支持 7×24 小时随时随地线上开户，只需一张有效证件，操作简单，信息安全。",
  HighSpeedOnlineAccountRegistration: "极速线上开户",
  SatisfyTradingNeedsatYourFingertipsText:
    "一个账户玩转美港股和全球期货，资金永不眠。 ",
  SatisfyTradingNeedsatYourFingertips: "弹指间满足交易需求",
  GlobalInvestmentsatYourFingertips: "全球投资，一键触达",
  GlobalInvestmentsatYourFingertipsText: "在 LayRay，享受一流的金融交易服务。",
  AboutDooFinancial: "关于 Lay Ray",
  AboutDooFinancialText: "Lay Ray 是互联网券商品牌，旨在为专业投资者提供全球证券、期货、差价合约等金融产品的交易经纪服务。 ",
  LeveragingText: "凭借集团领先的金融科技和金融生态优势，LayRay 实现了极速线上开户、出入金和交易的全流程电子化服务，为全球投资者提供方便、可靠的投资体验，实现一键配置全球金融资产。",
  Asafully: "作为一家全牌照互联网券商，LayRay 各地经营主体分别持有美国、英国等地的金融监管牌照，它们分别受到美国证监会（SEC）和金融监管局（FINRA），或英国金融行为监管局（FCA）等监管主体的严格监管。 ",
  VisionandMission: "愿景和使命",
  Emergeasaglobal: "成为一家以金融科技为核心、全球领先的互联网券商品牌。",
  Servingglobalinvestors: "服务全球投资者，将全球金融资产配置变成一件简单的事情。",
  OurValues: "我们的价值观",
  ClientSucess: "成就客户",
  Weprioritizeourclients: "我们始终将客户利益放在第一位，为客户提供安全、可靠、便捷的全球金融资产投资服务。",
  Trustworthy: "值得信赖",
  Asafinancialcompany: "我们深知作为一家金融公司，客户资产安全是我们的核心责任，这也是我们永远不变的核心价值追求。 ",
  EmbraceInnovation: "拥抱创新",
  Wefirmlybelievethatfinancialtechnologywillbring: "我们深信金融科技会对金融行业带来革命性的改变，我们会拥抱创新，持续创新，不断向前。",
  CompanyMilestone: "集团发展历程",
  Sinceitsestablishment: "LayRay 发展至今，已在全球多个地区的合规监管下，为超过 21,000  个专业客户提供最佳的交易体验。",
  Present: "现在",
  GlobalLayout: "全球布局",
  ProductInnovation: "产品革新",
  SetUpBangkok: "成立泰国曼谷办公室；",
  EstablishedDubaiofficeinUAE: "成立阿联酋迪拜办公室。",
  CooperatedtopromoteClearingCloud: "合作推进 Clearing Cloud 大型多边清算系统，协助业内不同规模的经纪商连接流动性提供商 ；",
  Offeredtraderswithmore: "为交易者提供超过10,000款交易产品。",
  BrandHistory: "品牌历史",
  LicenseRegulation: "安全监管",
  AcquiredPeterElishInvestmentsSecurities: "收购拥有 30 年历史的美国券商 Peter Elish ；",
  LaunchesDooFinancial: "发布 Lay Ray 在岸互联网券商品牌；",
  PartnerwithTradingCentralText: "与 Trading Central 合作，打造更多样化、更全面的专业技术分析工具。",
  SetupDallas: "成立美国达拉斯和越南胡志明市办公室。",
  Thegroupobtainedlicenses: "集团获得美国证监会 SEC 及金融业监管局 FINRA 牌照 ；",
  Clientswhoopenanaccount: "开户在美国的客户更可享受美国证券投资者保护公司（SIPC）提供高达 50 万美元的保障，有效保护客户的资金安全；",
  ObtainedalicensefromMauritius: "获得毛里求斯金融服务委员会 MFSC 牌照；",
  PurchasedProfessionalIndemnityInsurance: "购买了专业责任赔偿保险 (PII)，全方位保障客户资产安全和自身权益。",
  AddedfeaturedUS: "新增 300+ 个明星美港股及期货产品；",
  SignedacontracttoenterFOLLOWME: "签约进驻全球领先的外汇交易社区 FOLLOWME，携手提升客户的社交交易体验；",
  JoinedforceswithMTEMedia: "与一站式金融教育平台 MTE Media 联手提供海量的投资者教育资源 ；",
  CooperatedtobuildPriceCloud: "合作打造 Price Cloud 报价分发系统，提供极速稳定的多个市场报价接入及整合服务；",
  OfferedadedicatedVirtual: "提供 VPS 交易专用服务器，为客户建构更安全稳定的交易环境。",
  Institutionalclientsreached: "集团机构客户达到 400+；",
  StrategicpartnershipwithAlphaStrategy: "与 Alpha Strategy 建立战略合作伙伴关系，提供深度金融分析，包括每日研究报告以及货币和股票评论。",
  SetupSingapore: "成立新加坡、毛里求斯、塞舌尔和瓦努阿图办公室。",
  Thegroupobtained: "集团获得英国金融行为监管局 FCA 牌照；",
  Continuedtoexplorevarious: "不断拓展交易品种，接入更丰富美股、期货交易产品；",
  Cooperatedwithanumberoffintechcompanies: "与多家金融科技公司合作推出 InTrade 交易终端 及 Outrade 社区化交易平台；",
  ConnectedtoTradingView: "联通 TradingView 云端交易平台，协助客户交流海量的图表指标和交易想法；",
  Establishedastrategicpartnership: "与 Myfxbook 建立战略合作伙伴关系，强势助推 AutoTrade 镜像交易系统。",
  DooMT5trading: "LayRay MT5 交易平台正式启用，更强大更轻松，革新用户交易体验 ；",
  BrokerForceversion: "Broker Force v 4.0 升级亮相，提升报表统计功能。",
  LaunchedtheDooClearing: "推出 LayRay Clearing 清算品牌，为经纪商提供优质的流动性接入与清算服务 。",
  SetupLondonUKoffice: "成立英国伦敦办公室。",
  ObtainedalicensefromVanuatu: "获得瓦努阿图金融服务委员会 VFSC 牌照。",
  Introducedanewgeneration: "探索引入新一代交易软件 MT5，支持更多强大功能 。",
  SetupSeoulSouthKoreaoffice: "成立韩国首尔办公室。",
  Expandedpreciousmetals: "拓展贵金属、能源及全球主流指数交易产品。",
  DooTechlaunched: "LayRay Tech 金融科技服务商品牌，为投资者提供可信赖、经过市场测试的金融科技；",
  LaunchedDooForexasaretailbrokerage: "推出 LayRay Forex 作为零售经纪品牌，提供卓越的零售交易服务。 ",
  Introducedinternationally: "引入国际认可的专业交易软件 MT4，提供热门货币对产品；",
  CollaboratedwithFinPoints: "与新加坡金融科技公司  FinPoints  合作研发 BrokerForce  互联网券商客户管理系统。",
  DooGroupwasestablishedtocarryoutits: "LayRay 成立，开展集团企业化运营；",
  Establishedacore : "成立核心研发及技术支持团队。",
  FurtherGlobalGrowth: "布局全球，共创未来 ",
  Withofficesinevery: "LayRay 在世界各大金融中心均设有办事处，业务遍及全球，助您稳步走向全球市场 ",
  GlobalOffices: "全球办事处",
  ActiveClientsWorldwide: "全球活跃客户",
  TradingProducts: "交易产品",
  IndustryElites: "行业精英",
  IntroducedtheDooExchange :"发布 LayRay Exchange 品牌，提供诚信可靠、经济实惠的货币兑换服务 。",
  Thegroupobtainedatrust: "集团获得香港信托及公司服务提供者 TCSP 牌照。",
  EstablishedHongKong: "成立中国香港办公室。",
  TopTierQualityOrientedService: "卓越服务，以质为本",
  PremiumCustomerServiceprovided: "全球 500+ 行业精英为您提供优质的客户服务支持",
  ContactService: "在线客服",
  Email: "邮箱",
  SalesRepresentative: "客户经理",
  emailprotected: "[email&#160;protected]",
  TechnicalSupport: "技术支持",
  Marketing: "市场推广",
  RiskDisclosureofHigh: "高风险交易产品风险披露",
  Tradinginfinancialinstrumentsinvolveshigh: "由于基础金融工具的价值和价格会有剧烈变动，股票、证券、期货、差价合约和其他金融产品交易涉及高风险，可能会在短时间内发生超过您的初始投资的大额亏损。过去的投资表现并不代表其未来的表现，在与我们进行任何交易之前，请确保您完全了解使用相应金融工具进行交易的风险。 如果您不了解此处说明的风险，则应寻求独立专业的意见。 ",
  Youmayleaveyourcontactinformationhere: "留下您的联系方式，我们的专属顾问会在 1 个工作日内和您联系",
  FirstName: "姓",
  LastName: "名",
  PhoneNumber: "电话",
  CountryRegion: "国家 / 地区",
  RiskDisclosureofHighriskTradingInstruments: "高风险交易产品风险披露",
  RiskDisclosureofHighriskTradingInstrumentsText: "由于基础金融工具的价值和价格波动，金融工具的交易涉及高风险。由于不利和不可预测的市场变动，超过投资者初始投资的巨大损失可能会在短时间内产生。金融工具的过去表现并不表明其未来的表现。在与我们进行任何交易之前，请确保您阅读并充分了解各个金融工具的交易风险。如果您不了解我们本文披露的风险，则应寻求独立的专业建议。",
  DooFinancialisanInternetbrokerage: "Lay Ray 是互联网券商品牌， Lay Ray 在达拉斯、伦敦、新加坡、香港、迪拜、吉隆坡等设立了全球运营办事处，旨在通过全球化布局为客户提供专业便捷的客户服务。",
  Dallas: "达拉斯",
  EdwardsRanchRoad: "5049 Edwards Ranch Road, Suite 400, Fort Worth, TX 76109, United States",
  London: "London",
  BerkeleySuite: "Berkeley Suite 35 Berkeley Square, Mayfair, London, England, W1J 5BF",
  Singapore: "新加坡",
  MilleniaTower: "#24-01A, Millenia Tower, One Temasek Avenue, Singapore 039192.",
  HongKong: "香港",
  UnitA: "Unit A, 25/F, Tower B, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Hong Kong",
  Dubai: "迪拜",
  UnitBuildingBC04Emirates: "Unit 705A, B05, Building BC04, RAKEZ Business Zone - FZ Ras Al Khaimah, United Arab Emirates",
  Sydney: "悉尼",
  SuiteLevelPittStreet: "Suite 607 Level 6, 109 Pitt Street, Sydney NSW 2000",
  Cyprus: "Cyprus",
  AgiasFylakseos155: "Agias Fylakseos 155, First Floor, Office 102, 3083 Limassol, Cyprus",
  KualaLumpur: "吉隆坡",
  LevelJalanKerinchiKualaLumpurMalaysia: "Level 3, Tower 8, Avenue 5, The Horizon Phase 2, Bangsar South City, No. 8 Jalan Kerinchi, 59200 Kuala Lumpur, Malaysia",
  Thailand: "泰国",
  FloorRamaRoadHuaykwangSubdistrictHuaykwangDistrictBangkok: "28th Floor, Rama 9 Road, Huaykwang Sub-district, Huaykwang District, Bangkok",
  SouthAfrica: "South Africa",
  WestStSandtonJohannesburgGPSouthAfrica: "155 West St, Sandton, Johannesburg, GP, 2031, South Africa",
  Egypt: "Egypt",
  ElHoreyaAlmazahHeliopolisCairoGovernorateEgypt: "67 El-Horeya, Almazah, Heliopolis, Cairo Governorate 4461122, Egypt",
  Seychelles: "塞舌尔",
  RoomB111stFloorProvidence: "Room B11, 1st Floor, Providence Complex, Providence, Mahe, Seychelles",
  Mauritius: "毛里求斯",
  TheCyberatiLoungeGroundFloor: "The Cyberati Lounge, Ground Floor, The Catalyst, Silicon Avenue, 40 Cybercity, 72201 Ebene, Republic of Mauritius",
  Vanuatu: "瓦努阿图",
  GovantBuildingPoBox1276PortVilaVanuatu: "Govant Building, Po Box 1276, Port Vila, Vanuatu",
  CooperativeStockExchange: "合作证券交易所",
  CooperativeStockExchangeText: "LayRay 已与全球主要证券交易所达成深度合作，可为客户提供 10,000+ 美港股产品以 及高级行情报价服务，实现全球资产配置。",
  CooperativeFuturesExchange: "合作期货交易所",
  CooperativeFuturesExchangeText: "LayRay 与世界主要期货交易所均有合作，可提供股票指数、商品、能源、股票、利率、农产品等丰富的期货品种，以及优质的全球期货行情数据，真正实现一个账户交易全球期货市场。 ",
  MultipleInternationalLicenses: "全球多国持牌，合规值得信赖",
  DooFinancialistheonline: "LayRay 是大型金融服务集团 互联网券商品牌，其各地经营主体分别受美国 SEC 及 FINRA 、英国 FCA 、毛里求斯 FSC 以及瓦努阿图 VFSC 等监管机构的严格监管。",
  CuttingedgeTechnologywithFinancialInnovation: "尖端科技水平，专注金融创新 ",
  Asacuttingedgeonline: "作为新一代互联网券商，LayRay 专注于技术研发与科技创新，拥有严格的数据安保系统和强大的硬件配套设施，并不断丰富功能、提升执行力，致力于成为全球领先的科技券商，为客户提供极致的交易体验。",
  GlobalExpansionthroughInternationalDevelopment: "全球布局，坚持国际化发展",
  DooFinancialsparent: "LayRay  致力于构建全球生态网络，在达拉斯、伦敦、新加坡、香港、迪拜、吉隆坡等地设有运营中心，旨在通过全球化的布局，为高净值客户提供一站式全球投资和金融交易服务。",
  WhyPartnerwithUs: "为什么选择与我们合作",
  GroundbreakingFundingManagement : "跨越全球的资金存取",
  Secureandexpeditedtradingenvironmentatyourfingertips: "强大实力造就安全投资环境，交易随心、灵活存取。",
  Strongcapitalhighendriskmanagement: "雄厚资本，高端风险管理",
  DooGroupisalarge: "LayRay 是一家以金融科技为核心的大型综合服务集团，强大的资本实力、前沿的风险管理科技及接受全球多家金融监管机构的严苛监管，全方位保护客户的投资安全。 ",
  USD5million: "500 万+",
  RegulatoryCapitalRequirement: "监管资本金要求 (USD)",
  AccountRegistered: "客户账户",
  USD15billion: "150 亿+",
  AverageMonthlyTradingVolume: "Average Monthly Trading Volume",
  ConstructiveCooperationwithDistinguishedBanks: "强强联手，合作实力银行",
  Tofairlydistributeandsecureourclients: "LayRay 与全球众多主要银行建立合作关系，客户存款分布在多家投资级银行。",
  SegregationandClientFunds: "资金隔离，专款专用",
  DooFinancialsegregatesclient:"LayRay 把客户资金与公司运营资金隔离托管于：渣打银行、贝森银行、巴克莱银行。",
  Weadheretotherigoroussystemofinternationalbanks: "我们接受国际银行严格审核制度，客户资金专款专用并始终受到保护。 ",
  ProtectingClientsAssestInsurance: "专业保险，提供全面保障",
  Accountsmaintainedwith: "在 LayRay 的美国金融实体开设的账户将受到美国证券投资者保护公司 (SIPC) 的保护，保险金额最高可达 500,000 美元（现金限额为 250,000 美元）。* ",
  ConvenientandDiverseDepositandWithdrawalMethods: "便捷、多样的存取款方式",
  AtDooFinancialdepositandwithdrawyour: "LayRay 提供多种安全、简单、快速的资金存取方式，让您全程投入交易，毋须担心资金安全。 ",
  Supportedcurrencies: "支持货币：AED、AUD、BRL、COP、CLP、CNY、EUR、EUSD、GBP、HKD、KRW、KHR、LAK、MMK、MXN、NGN、PEN、THB、USD、USDT、VND、ZAR",
  Deposit: "存款",
  LocalBankTransfer: "本地银行存款",
  PaymentChannel: "支付渠道",
  PaymentCurrency: "支付货币",
  LimitUSD: "限额（USD）",
  Fees: "费用",
  ProcessingTime: "处理时间",
  Nocharges: "无手续费",
  Instant: "快速",
  minutes: "分钟",
  Charges: "有手续费",
  workingdays25: "2-5个工作日",
  LocalBankTransferText1: "1个工作日，即 T + 0 (USD 0-50,000)",
  LocalBankTransferText2: "2个工作日 (USD 50,000-200,000) ",
  LocalBankTransferText3: "5个工作日 (USD 200,000-1,000,00)",
  Notesondepositmethod: "存款方式注意事项：",
  NotesondepositmethodText1: "1. 建议最低存款金额 100 美金；",
  NotesondepositmethodText2: "2. 其他货币类型按照美元汇率换算；",
  NotesondepositmethodText3: "3.所有入金方式都需要由本人账户支付，非本人支付将按付款人信息原路退回； ",
  NotesondepositmethodText4: "4.所有转账请勿填写任何备注，如有备注/附言敏感信息会造成交易失败，会被原路退回；",
  NotesondepositmethodText5: "5.客户转账金额与订单需支付金额不符的情况，订单无法调整所以无法根据实际到账金额折算入金，客户多付，支付服务提供商会安排退回。客户少付，支付服务提供商会要求补齐金额后放行订单或者安排退回。",
  WithdrawalMethods: "出金方式",
  InternationalWireTransfer: "国际电汇",
  InternationalWireTransferText1: "1个工作日，即 T + 0 (USD 0-50,000)",
  InternationalWireTransferText2: "2个工作日 (USD 50,000-200,000) ",
  InternationalWireTransferText3: "5个工作日 (USD 200,000-1,000,00) ",
  InternationalWireTransferText4: "6个工作日 (USD 1,000,000 以上)",
  Pleaseberemindedthatwhenwithdrawing: "取款方式注意事项： ",
  PleaseberemindedthatwhenwithdrawingText1: "1. 建议最低取款金额 50 美金；",
  Ewallet: "电子钱包",
  Withdrawal: "取款",
  ActiveAccount: "活动帐户",
  VerifiedAccount: "验证帐户",
  Theindustryslowest: "行业低廉透明的佣金 ，助交易者降低成本、将利益最大化。",
  USSecuritiesTrading: "美股交易",
  LowCostFlexibleandTransparent: "价格低廉，灵活透明",
  PerShare: "每股",
  PerTransactionMinimum: "每笔最低",
  AgentFee: "代收费用",
  RegulatoryFees: "证监会费",
  Tradingactivityfeesetc: "交易活动费等",
  FindOutMore: "了解详情",
  HongKongSecuritiesTrading: "港股交易",
  TopintheIndustrywithUltraLowCommission: "超低佣金，业内领先",
  Aslowas: "低至",
  Minimum: "低至",
  PlatformFee: "平台使用费",
  pershare: "每股",
  StampDuty: "印花税",
  FuturesTrading: "期货交易",
  BetterPriceGreaterSavings: "节省成本，价格更优",
  Percontractforperside: "每张合约每边收取",
  Exchangefees: "交易所费用",
  Regulatoryfeesetc: "监管费等",
  CFDsTrading: "差价合约交易",
  UltraLowSpreadswithMaximumSecurity: "极低点差，值得信赖",
  SpreadsorCommission: "点差或佣金",
  SpreadsorCommissionText: "点差是买入价与卖出价之差，是差价合约交易的主要交易成本，由于差价费用并非固定，您可登录到交易平台查看，而股票差价合约交易，您需要支付的是佣金。",
  OvernightInterest: "隔夜利息",
  OvernightInterestText: "如果您在每日结算时间过后仍持有现货差价合约仓位，您将会被收取隔夜费用，有关每个产品的隔夜利息可登录到交易平台查看。",
  OtherFees: "其他费用",
  OtherFeesText: "无其他费用。我们的收费政策完全透明，任何需要收费的项目都将提前公布并告知，无需担心隐性收费。",

};
