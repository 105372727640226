import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/products",
    name: "products",
    component: () => import(/* webpackChunkName: "products" */ "../views/Products.vue"),
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () => import(/* webpackChunkName: "pricing" */ "../views/Pricing.vue"),
  },
  {
    path: "/support",
    name: "support",
    component: () => import(/* webpackChunkName: "support" */ "../views/Support.vue"),
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: () => import(/* webpackChunkName: "terms-and-conditions" */ "../views/TermsAndConditions.vue"),
  },
  {
    path: "/policies-and-procedures",
    name: "policies-and-procedures",
    component: () => import(/* webpackChunkName: "policies-and-procedures" */ "../views/PoliciesAndProcedures.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import(/* webpackChunkName: "privacy-policy" */ "../views/PrivacyPolicy.vue"),
  },
  {
    path: "/disclosure",
    name: "disclosure",
    component: () => import(/* webpackChunkName: "disclosure" */ "../views/Disclosure.vue"),
  },
  {
    path: "/investor-attention",
    name: "investor-attention",
    component: () => import(/* webpackChunkName: "investor-attention" */ "../views/InvestorAttention.vue"),
  },
  /* {
    path: '/:pathMatch(.*)',
    redirect: '/'
  }, */
];
const  scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    return { left: 0, top:0 };
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

export default router;
