<template>
    <footer :theme="theme" id="footer">
        <div class="container">
            <div class="row between main-footer">
                <div class="columns three">
                    <div class="footer-logo">
                        <img src="@/assets/images/footer-logo.png" alt="">
                    </div>

                    <!-- <h5 class="contactnumber"><i class="icon-phone"></i>+91 80 4718 1888</h5> -->
                    <p class="copyright">© 2010 - 2024, Bats Broking Ltd.</p>
                    <p class="copyright">All rights reserved.</p>
                    <ul class="social">
                        <li>
                            <a href="#">
                                <img src="@/assets/images/x-twitter.svg" alt="">
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img src="@/assets/images/f.svg" alt="">
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img src="@/assets/images/h.svg" alt="">
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img src="@/assets/images/in.svg" alt="">
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img src="@/assets/images/t.svg" alt="">
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img class="youtube-logo" src="@/assets/images/youtube.svg" alt="">
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="columns three">
                    <ul class="list-style">
                        <li class="nav-head auto-color" :theme="theme">Company</li>
                        <li><a href="/about">About</a></li>
                        <li><a href="/products">Products</a></li>
                        <li><a href="/pricing">Pricing</a></li>
                        <!-- <li>
                            <a href="https://Bats.com/refer/">Referral programme</a>
                        </li>
                        <li><a href="https://careers.Bats.com">Careers</a></li>
                        <li><a href="https://Bats.tech">Bats.tech</a></li>
                        <li><a href="https://Bats.com/media/">Press &amp; media</a></li>
                        <li><a href="https://Bats.com/cares/">Bats Cares (CSR)</a></li> -->
                    </ul>
                </div>
                <div class="columns three">
                    <ul class="list-style">
                        <li class="nav-head auto-color" :theme="theme">Support</li>
                        <li><a href="/support">Contact us</a></li>
                        <!-- <li>
                            <a href="https://support.Bats.com">Support portal</a>
                        </li>
                        <li><a href="https://Bats.com/z-connect/">Z-Connect blog</a></li>
                        <li><a href="https://Bats.com/charges/">List of charges</a></li> -->
                        <li>
                            <a href="/products">Downloads</a>
                        </li>
                       <!--  <li>
                            <a href="/videos">Videos</a>
                        </li>
                        <li>
                            <a href="https://stocks.Bats.com">Market overview</a>
                        </li>
                        <li>
                            <a target="_blank"
                                href="https://support.Bats.com/category/your-Bats-account/your-profile/ticket-creation/articles/how-do-i-create-a-ticket-at-Bats">How
                                to file a complaint?</a>
                        </li>
                        <li>
                            <a target="_blank"
                                href="https://support.Bats.com/category/your-Bats-account/your-profile/ticket-creation/articles/track-complaints-or-tickets">Status
                                of your complaints</a>
                        </li> -->
                    </ul>
                </div>
                <div class="columns three">
                    <ul class="list-style">
                        <li class="nav-head auto-color" :theme="theme">Account</li>
                        <li>
                            <a target="_blank" href="https://www.batste.com">Open an account</a>
                        </li>
                       <!--  <li>
                            <a href="https://Bats.com/fund-transfer/">Fund transfer</a>
                        </li> -->
                    </ul>
                </div>
            </div>

            <div class="row smallprint">
                <p>
                    Bats Broking Ltd.: Member of NSE, BSE​ &amp;​ MCX – SEBI
                    Registration no.: INZ000031633 CDSL/NSDL: Depository services through Bats Broking Ltd. – SEBI
                    Registration no.: IN-DP-431-2019
                    Commodity Trading through Bats Commodities Pvt. Ltd. MCX: 46025; NSE-50001
                    – SEBI Registration no.: INZ000038238 Registered Address: Bats
                    Broking Ltd., #153/154, 4th Cross, Dollars Colony, Opp. Clarence Public
                    School, J.P Nagar 4th Phase, Bengaluru - 560078, Karnataka, India. For
                    any complaints pertaining to securities broking please write to
                    <a href="mailto:complaints@Bats.com">complaints@Bats.com</a>, for
                    DP related to <a href="mailto:dp@Bats.com">dp@Bats.com</a>. Please
                    ensure you carefully read the Risk Disclosure Document as prescribed by
                    SEBI | ICF
                </p>
                <p>
                    Procedure to file a complaint on <a href="https://scores.gov.in/scores/Welcome.html"
                        target="_blank">SEBI SCORES</a>:
                    Register on SCORES portal. Mandatory details for filing complaints on SCORES: Name, PAN, Address,
                    Mobile Number,
                    E-mail ID.
                    Benefits: Effective Communication, Speedy redressal of the grievances
                </p>
                <p>
                    <a href="https://smartodr.in/" target="_blank">Smart Online Dispute Resolution</a>
                </p>
                <p>
                    Investments in securities market are subject to market risks; read all the related documents
                    carefully before investing.
                </p>
                <p>
                    Attention investors: 1) Stock brokers can accept securities as margins from clients only by way of
                    pledge in the depository system w.e.f September 01, 2020. 2) Update your e-mail and phone number
                    with your stock broker / depository participant and receive OTP directly from depository on your
                    e-mail and/or mobile number to create pledge. 3) Check your securities / MF / bonds in the
                    consolidated account statement issued by NSDL/CDSL every month.
                </p>
                <p>
                    "Prevent unauthorised transactions in your account. Update your mobile
                    numbers/email IDs with your stock brokers. Receive information of your
                    transactions directly from Exchange on your mobile/email at the end of
                    the day. Issued in the interest of investors. KYC is one time exercise
                    while dealing in securities markets - once KYC is done through a SEBI
                    registered intermediary (broker, DP, Mutual Fund etc.), you need not
                    undergo the same process again when you approach another intermediary."
                    Dear Investor, if you are subscribing to an IPO, there is no need to
                    issue a cheque. Please write the Bank account number and sign the IPO
                    application form to authorize your bank to make payment in case of
                    allotment. In case of non allotment the funds will remain in your bank
                    account. As a business we don't give stock tips, and have not authorized
                    anyone to trade on behalf of others. If you find anyone claiming to be
                    part of Bats and offering such services, please
                    <a
                        href="https://support.Bats.com/category/your-Bats-account/your-profile/articles/how-do-i-place-a-complaint-at-Bats">create
                        a ticket here</a>.
                </p>
            </div>
            <div class="footer-graveyard-links text-center">
                <ul>
                    <li>
                        <a href="https://nseindia.com">NSE</a>
                    </li>
                    <li>
                        <a href="https://www.bseindia.com/">BSE</a>
                    </li>
                    <li>
                        <a href="https://www.mcxindia.com/">MCX</a>
                    </li>
                    <li>
                        <a href="/terms-and-conditions">Terms &amp; conditions</a>
                    </li>
                    <li>
                        <a href="/policies-and-procedures">Policies &amp; procedures</a>
                    </li>
                    <li>
                        <a href="/privacy-policy">Privacy policy</a>
                    </li>
                    <li>
                        <a href="/disclosure">Disclosure</a>
                    </li>
                    <li>
                        <a href="/investor-attention">For investor's attention</a>
                    </li>
                    <!-- <li>
                        <a href="/tos/investor-charter">Investor charter</a>
                    </li> -->
                </ul>
            </div>
        </div>
    </footer>
</template>

<script setup>
    import { computed } from "vue";
    import { useStore } from 'vuex'
    const store = useStore()
    const theme = computed(() => store.getters.theme);
</script>

<style lang="scss" scoped>
</style>