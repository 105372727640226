import { LANG, THEME } from '@/constant'
import { getItem, setItem } from '@/utils/storage'
export default {
  namespaced: true,
  state: () => ({
    language: getItem(LANG) || 'en',
    theme: 'light',
    linkObj: getItem('linkUrl') || {}
  }),
  mutations: {
    /**
     * 设置国际化
     */
    setLanguage(state, lang) {
      setItem(LANG, lang)
      state.language = lang
    },
    /**
     * 设置主题
     */
    setTheme(state, theme){
      setItem(THEME, theme)
      state.theme = theme
    },
    /**
     * 设置链接地址
     */
    setLink(state, obj) {
      setItem('linkUrl', obj)
      state.linkObj = obj
    },
  },
  actions: {}
}
