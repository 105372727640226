<template>
  <div class="header" :theme="theme">
    <div class="head_top_nav_wap" :theme="theme" :style="{'display':open?'flex':'none'}">
      <div class="wap-logo">
        <img
          src="@/assets/images/logo.png"
          alt
          width="127"
          height="30"
        />
      </div>
      <span class="close" @click="close" :theme="theme"> × </span>
      <ul
        id="menu-%e6%89%8b%e6%9c%ba%e7%89%88%e4%b8%bb%e8%8f%9c%e5%8d%95"
        class="ul_head_top_nav"
      >
        <li
          id="menu-item-237"
           :theme="theme"
        >
          <a title="Signup" target="_blank" :href="linkObj.h5DownloadUrl" class="nav-link">Signup</a>
        </li>
        <li
          id="menu-item-90"
           :theme="theme"
           :class="[path=='about'?'active':'']"
        >
          <a title="About" href="/about" class="nav-link">About</a>
        </li>
        <li
          id="menu-item-91"
           :theme="theme"
           :class="[path=='products'?'active':'']"
        >
          <a title="Products" href="/products" class="nav-link">Products</a>
        </li>
        <li
          id="menu-item-92"
           :theme="theme"
           :class="[path=='pricing'?'active':'']"
        >
          <a title="Pricing" href="/pricing" class="nav-link">Pricing</a>
        </li>
        <li
          id="menu-item-93"
          :theme="theme"
          :class="[path=='support'?'active':'']"
        >
          <a title="Support" href="/support" class="nav-link">Support</a>
        </li>
        <!-- <li
          id="menu-item-94"
          :theme="theme"
        >
          <div style="padding:0 20px">
            <img v-show="theme=='dark'"  @click="changeTheme('light')" style="cursor: pointer;" src="@/assets/images/dark.svg">
            <img v-show="theme=='light'" @click="changeTheme('dark')" style="cursor: pointer;" src="@/assets/images/bright.svg">
          </div>
        </li> -->
      </ul>
    </div>
    <div class="top" :theme="theme">
      <div class="logo">
        <a href="/" id="biglogo_img_url"
          ><img
            src="@/assets/images/logo.png"
            alt
            width="159"
        /></a>
      </div>
      <div class="head_top_nav">
        <ul id="menu-main-menu" class="ul_head_top_nav">
          <li
            id="menu-item-231"
            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-173 current_page_item menu-item-231 nav-item"
            :class="[path=='signup'?'active':'']"
          >
            <a title="Signup" target="_blank" :href="linkObj.h5DownloadUrl" class="nav-link">Signup</a>
          </li>
          <li
            id="menu-item-6"
            :class="[path=='about'?'active':'']"
          >
            <a title="About" href="/about" class="nav-link">About</a>
          </li>
          <li
            id="menu-item-15"
            :class="[path=='products'?'active':'']"
          >
            <a title="Products" href="/products" class="nav-link">Products</a>
          </li>
          <li
            id="menu-item-16"
            :class="[path=='pricing'?'active':'']"
          >
            <a title="Pricing" href="/pricing" class="nav-link">Pricing</a>
          </li>
          <li
            id="menu-item-17"
            :class="[path=='support'?'active':'']"
          >
            <a
              title="Support"
              href="/support"
              class="nav-link"
              >Support</a
            >
          </li>
          <!-- <li
            id="menu-item-18"
          >
            <img v-show="theme=='dark'"  @click="changeTheme('light')" style="cursor: pointer;" src="@/assets/images/dark.svg">
            <img v-show="theme=='light'" @click="changeTheme('dark')" style="cursor: pointer;" src="@/assets/images/bright.svg">
          </li> -->
        </ul>
      </div>
      <div class="wap-menu">
        <img @click="changeOpen"
          src="@/assets/images/icons/menu.svg"
          alt
          width="24"
        />
      </div>
    </div>
    <div class="menu-bg"></div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { onMounted, onUnmounted,ref,computed  } from "vue";
import { useStore } from 'vuex'
import { setItem,getItem } from '@/utils/storage'
import { useRouter } from 'vue-router';

// const language = computed(() => store.getters.language);
// 切换语言的方法
const path = ref(location.pathname.replaceAll('/',''))
const { locale } = useI18n()
const store = useStore()
const open = ref(false)
const changeOpen = ()=>{
  open.value= true;
}
const close = ()=>{
  open.value= false;
}
const changeLang = (lang) => {
  locale.value = lang;
  store.commit("app/setLanguage", lang);
  location.reload()
}
const theme = computed(() => store.getters.theme);
const linkObj = computed(() => store.getters.linkObj);
const changeTheme = (type)=>{
  theme.value = type;
  store.commit("app/setTheme", type);
  close()
  setTheme()
}
const setTheme = ()=>{
  document.body.style.backgroundColor= theme.value == 'dark' ? '#102131':'#f8fafd'
  document.body.className= theme.value
}
onMounted(() => {
  setTheme()
  window.addEventListener('resize',()=>{
    close()
  })
});
onUnmounted(()=>{
  window.removeEventListener('resize',false);
})

</script>

<style lang="scss" scoped>
.language_area {
  padding: 0;
}
.trp-language-switcher {
  position: relative;
  display: inline-block;
  padding: 0;
  border: 0;
  margin: 2px;
  box-sizing: border-box;
}
.trp-language-switcher > div {
  box-sizing: border-box;
  padding: 3px 20px 3px 5px;
  border: 1px solid #c1c1c1;
  border-radius: 3px;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-image: none;
  background-position: calc(100% - 8px) calc(1em + 0px),
    calc(100% - 3px) calc(1em + 0px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
}

.trp-language-switcher > div > a {
  padding: 0;
}

.trp-language-switcher > div {
  box-sizing: border-box;
  padding: 3px 20px 3px 5px;
  border: 1px solid #c1c1c1;
  border-radius: 3px;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-image: none;
  background-position: calc(100% - 8px) calc(1em + 0px),
    calc(100% - 3px) calc(1em + 0px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
}

.trp-language-switcher > div > a {
  padding: 0;
}

.trp-language-switcher-container
  > div.trp-ls-shortcode-current-language:first-child::after {
  content: "";
  display: inline-block;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  border: 5px solid transparent;
  border-top-color: #222;
  position: absolute;
  top: 25px;
  right: 8px;
  width: 0;
  height: 0;
}
.trp-language-switcher-container
  > div.trp-ls-shortcode-current-language:first-child::after {
  content: "";
  display: inline-block;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  border: 5px solid transparent;
  border-top-color: #222;
  position: absolute;
  top: 25px;
  right: 8px;
  width: 0;
  height: 0;
}
.trp-language-switcher:focus .trp-ls-shortcode-language,
.trp-language-switcher:hover .trp-ls-shortcode-language {
  visibility: visible;
  max-height: 250px;
  height: auto;
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block !important;
  min-height: auto;
}
.trp-language-switcher > div > a {
  display: block;
  padding: 2px;
  border-radius: 3px;
  color: rgb(7, 105, 173);
}
.language_area a:hover {
  color: #d41e44;
  transition: all 600ms;
}

.trp-language-switcher > div > a.trp-ls-shortcode-disabled-language:hover {
  background: none;
}
.trp-language-switcher > div > a.trp-ls-shortcode-disabled-language {
  cursor: default;
}
.trp-language-switcher:focus .trp-ls-shortcode-language,
.trp-language-switcher:hover .trp-ls-shortcode-language {
  visibility: visible;
  max-height: 250px;
  height: auto;
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block !important;
  min-height: auto;
}
</style>
