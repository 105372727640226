<template>
  <layout-header></layout-header>
  <router-view />
  <layout-footer></layout-footer>
</template>

<script setup>
import LayoutHeader from "@/components/LayoutHeader";
import LayoutFooter from "@/components/LayoutFooter";
import { useStore } from 'vuex'

const store = useStore()
function sendGetRequest(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);

    xhr.onload = function () {
        if (xhr.status === 200) {
            const response = JSON.parse(xhr.responseText);
            callback(null, response);
        } else {
            callback("Error: " + xhr.status);
        }
    };

    xhr.onerror = function () {
        callback("Request failed");
    };

    xhr.send();
}
const apiUrl = "https://in2prod9527.protbg.com/web/config/configKey?configKey=official.website";

sendGetRequest(apiUrl, function (error, res) {
    if (error) {
        console.error(error);
    } else {
        store.commit("app/setLink", res.data);
    }
});
</script>

<style lang="scss">
.all-product-part .swiper-pagination-customs {
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #8a8f9a;
  margin-left: 16px;
}

.all-product-part .swiper-pagination1 {
  position: relative;
  bottom: -30px;
}
.all-product-part .swiper-pagination-customs-active {
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #d41e44;
  margin-left: 16px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
