export default {
  AdvancedtechnologypowerfulperformanceText:
    "Lay Ray InTrade 具备强劲的系统性能，包括良好的系统兼容性，可在任何浏览器上使用。",
  Advancedtechnologypowerfulperformance: "先进技术，强大性能",
  VarietyrichchartsvisualanalyticsText:
    "丰富的专业图表直观展示，可自定义多种技术指标，通过绘图工具进行便捷的数据分析。",
  Varietyrichchartsvisualanalytics: "丰富图表，可视化分析",
  CloudBasedTradingText:
    "无需下载交易软件，即可在 Lay Ray InTrade 网页版开展交易，不断优化升级的系统功能，让您拥有可靠的交易体验。",
  CloudBasedTrading: "云上交易，操纵自如",
  AGlobalViewoftheMarketText:
    "整合全球证券、期货等市场的权威消息，打开 Lay Ray InTrade 便可速览市场全局。",
  AGlobalViewoftheMarket: "市场全局尽览",
  ManageAccountinJustaClickText:
    "功能完备的掌上云端交易软件，支持一键管理所有账户。",
  ManageAccountinJustaClick: "账户综合管理",
  UserfriendlyInterfaceText:
    "清晰简洁的软件界面设计，不论是查看账户信息，还是进行交易操作，都可轻松上手。",
  UserfriendlyInterface: "清晰简洁易用",
  RealTimePriceUpdatesText:
    "对接国际市场，报价实时更新，让您轻松查看感兴趣的美港股、期货等产品。",
  RealTimePriceUpdates: "报价实时更新",
  EverythingatYourFingertipsText:
    "通过 Lay Ray InTrade 移动端，可随时查看账户信息，追踪市场动态，不错过任何一个投资机会。",
  EverythingatYourFingertips: "一切尽在掌握",
  TopInformationText:
    "获取丰富而全面的行业资讯，专业的市场解读，把握市场脉搏。",
  TopInformation: "热门信息",
  MultidimensionalVarietyAnalysisText:
    "提供实时市场深度和精确的走势图表，助您纵横美港股和期货市场。",
  MultidimensionalVarietyAnalysis: "多维度品种分析",
  IndepthTradingAnalysisText: "愈多精确分析，愈多明智决策",
  IndepthTradingAnalysis: "深度交易分析",
  SecureTransactionsText:
    "系统的分布式、异地多活的服务器架构，有效防止故障，保障客户的业务全天候在线，可用性高达99.999%。",
  SecureTransactions: "安全交易",
  PersonalisationText:
    "支持对系统及图表显示的多项自定义设置，并提供多种专业绘图工具与语言版本，满足各个国家及地区的客户需求。",
  Personalisation: "个性化设置",
  CrossdeviceTradingText:
    "基于云端网络的交易系统，提供网页版和移动端，您可以随时随地在任意的设备上开始交易，及时把握投资机遇。",
  CrossdeviceTrading: "跨设备交易",
  StabilityandSpeedInTradeText:
    "毫秒级订单执行速度，专线直连全球顶级交易所，仅需 0.05 秒完成指令，随时随地快速下单。",
  StabilityandSpeedInTrade: "稳定与速度兼备",
  CuttingedgeTechnologyText:
    "以云端和顶级网络科技搭载多项尖端技术，且已接入全球骨干网络线路，无论您身在何处都能立刻开启交易",
  CuttingedgeTechnology: "尖端技术造就强大性能",
  ProfessionalOperationInterfaceText:
    "个性化设置，感受功能齐全、友好易用的专业操作界面",
  ProfessionalOperationInterface: "专业的操作界面",
  OneclickJumpText:
    "登录 Lay Ray 用户中心，即可一键跳转 Lay Ray InTrade 交易平台，随时启动云端交易",
  OneclickJump: "用户中心一键跳转",
  OnestopCustomerManagementText:
    "客户可一键打开 Lay Ray InTrade，随时随地开始交易，管理投资决策",
  OnestopCustomerManagement: "一站式客户管理",
  IntuitiveTradingDataText:
    "实时获取全面直观的交易数据，包括交易品种、订单类型、交易量等。",
  IntuitiveTradingData: "直观交易数据",
  TPTVMetaTrader5Text: "新一代交易软件",
  TPTVMetaTrader4Text: "全球最热门的零售交易平台",
  TPTVDooFinancialInTradeText: "以移动端为核心的专业交易平台",
  TPTVAVarietyofSteadfastTradingSoftwareText:
    "在 Lay Ray 挑选最适合您的交易平台，获取最佳交易体验",
  TPTVAVarietyofSteadfastTradingSoftware: "其他多款顶尖交易软件",
  TPTVRegister: "立即开户",
  TPTVConnecttoText:
    "建立在广域网之上, 无需专门的网络硬件环境，且信号传输更为稳定",
  TPTVConnectto: "联通 Lay Ray 交易",
  TPTVAccessibleAnytimeAnywhereText:
    "图表适用于任何操作系统、任何设备和任何浏览器。 ",
  TPTVAccessibleAnytimeAnywhere: "随时随地使用",
  TPTVFrequentlyUpdatedChartsText:
    "根据最新报价，每秒钟多次更新图表, 加载大量数据仍回应灵敏。",
  TPTVFrequentlyUpdatedCharts: "图表频密更新",
  TPTVSupportsMultipleLanguagesText:
    "含有 30 多种不同语言，完全本地化并可随时部署。",
  TPTVSupportsMultipleLanguages: "支持多种语言",
  TPTVNewTradingPerspectiveText:
    "每日有成千上万的观点和直播发布，全球市场分析唾手可得。",
  TPTVNewTradingPerspective: "崭新交易观点",
  TPTV10MillionTradersText:
    "不论您是新手或专业交易者，都可在这庞大社区发掘投资思路，不断进步。",
  TPTV10MillionTraders: "1000万+ 交易者的圈子",
  TPTVComprehensiveTradingToolsText:
    "用于快速访问商品代码的自选表、热门交易品种列表和发掘更多强大的功能。",
  TPTVComprehensiveTradingTools: "交易工具全面，信息细密无遗",
  TPTVGlobalMarketDataCoverageText:
    "TradingView 稳定连接到数百个数据源，直接访问世界各地的 50 多个证券交易所的实时数据，并第一时间提供财经及时事热点信息。",
  TPTVGlobalMarketDataCoverage: "全球实时信息，秒秒尽在掌握",
  TradingPlatformHighlyUserfriendlyInterfaceText:
    "串流显示市场深度、历史成交数据，并提供额外的订单选项，让交易者使用帐户对应的货币金额或帐户余额的百分比，设置所需的止盈以及止损位置。",
  TradingPlatformHighlyUserfriendlyInterface: "操作便捷简单，切合交易需要",
  TradingPlatformImmenseTechnicalAnalysisText:
    "您可获取来自 100+ 国家或地区、数以千万个交易者的观点及市场分析，同时亦可编写和分享技术分析指标及策略，在投资过程中塑造出自己的交易风格。",
  TradingPlatformImmenseTechnicalAnalysis: "海量技术分析，追踪市场脉搏",
  TradingPlatformStayAbreastoftheMarketText:
    "您可将 12 种不同的警报条件应用于指标，策略和绘图工具，以接收实时推播通知，避免错过任何交易机会。所有警报均在具有备份功能的强大服务器端运行，以提高可靠性。",
  TradingPlatformStayAbreastoftheMarket: "实时推送通知，时刻紧贴市场",
  TradingPlatformEfficaciousMarketFilterText:
    "根据您指定的条件自动筛选出表现最理想的投资标的，实时展示准确结果，大幅节省手动操作的时间。",
  TradingPlatformEfficaciousMarketFilter: "实用市场筛选器，提升操盘效率",
  TradingPlatformUpperEndText:
    "内建的 Pine 脚本更能让交易者创造出自己的指针或交易程序，而“ HTML5 Canvas ”技术为平台提供最佳的性能和灵活性，确保图表在任何设备上看起来完美准确。",
  TradingPlatformUpperEnd: "高端 Pine 脚本，随心创建策略",
  TradingPlatformIndepthAnalysisToolstoTackletheMarketText:
    "50 多种智能绘图工具、12 种可定制的图表类型、100 多种技术指标及成交量分布（PV）等深度分析市场的工具组供您选用。",
  TradingPlatformIndepthAnalysisToolstoTackletheMarket:
    "深度分析工具，参透市场玄机",
  TradingPlatformFulfillingAllYourTradingNeeds: "全方位满足您的交易需求",
  TradingPlatformChartsCreated: "已创建图表",
  TradingPlatformTradingIdeas: "交易观点",
  TradingPlatformTraders: "使用交易者",
  TradingPlatformTradingViewTitle: "汇集千万专业投资者的交易社区",
  TradingViewDsc:
    "提供海量分析图表、追踪市场行情及研究专家 交易观点，让交易如虎添翼。",
  TPmetatraderAVarietyofSteadfastTradingSoftwareText:
    "在 Lay Ray 挑选最适合您的交易平台，获取最佳交易体验",
  TPmetatraderAVarietyofSteadfastTradingSoftware: "其他多款顶尖交易软件",
  TPmetatraderMore: "了解更多",
  TPmetatraderMarketDepth: "市场深度",
  TPmetatraderPendingOrderType: "部分订单执行政策",
  TPmetatraderPartialorderexecutionpolicy: "部分订单执行政策",
  TPmetatraderMT4VSMT5Text:
    "两种软件提供了不同的功能和特性，可针对不同的交易对象提供更专业的服务，请根据您的交易习惯及交易目标，选择更合适您的一款",
  TPmetatraderMT4VSMT5: "MT4 与 MT5",
  TPmetatraderMetaTrader4MobileText:
    "下载 Lay Ray MT4 移动端，全天候穿梭市场，随时随地进行交易，把握市场机遇。",
  TPmetatraderMetaTrader4Mobile: "MetaTrader 4 移动端",
  TPmetatraderMetaTrader4DesktopVersionText:
    "下载 Lay Ray MT4 桌面版，获得市场上最强大、最便捷的技术分析和交易工具，享受最广泛的买卖机会。",
  TPmetatraderMetaTrader4DesktopVersion: "MetaTrader 4 桌面版",
  TPmetatraderMQL5sAlgorithmicTradingStrategiesText:
    "面向高阶用户的编程语言，提供大量函数以及内置技术指标，用于报价分析，大幅提高交易者的执行效率。",
  TPmetatraderMQL5sAlgorithmicTradingStrategies: "用于交易策略的 MQL5",
  TPmetatraderExtensiveFeaturesText:
    "支持 EA 自动化交易及多账户管理系统，同时兼备高度的通用性及可定制化，且提供多种语言选择。 ",
  TPmetatraderExtensiveFeatures: "功能全面",
  TPmetatraderBackTestingofHistoricQuotesText:
    "通过回测统计历史获利效益，评估和调整交易策略，助您做出明智的交易决策。 ",
  TPmetatraderBackTestingofHistoricQuotes: "可回测",
  TPmetatraderOperatingRoundtheClockText:
    "24 小时活跃交投状态，全天候自动执行您所设定的交易条件，为您增加收益。 ",
  TPmetatraderOperatingRoundtheClock: "全天候",
  TPmetatraderHighspeedExecutionText:
    "自动分析行情走势并识别市场机会，买进抛出、低买高抛，自动完成整个交易过程，高效便捷。 ",
  TPmetatraderHighspeedExecution: "高速执行",
  TPmetatraderTradewithEAonMT4Text:
    "个性化定制交易策略，系统自动执行交易，解放您的双手。",
  TPmetatraderTradewithEAonMT4: "MT4 与 EA 智能交易",
  TPmetatraderExpeditiousTradingExperienceText:
    "透过 Lay Ray MT4 的 VPS 交易专用服务器来进行自动交易，以最小的延时执行买卖指令。",
  TPmetatraderExpeditiousTradingExperience: "极速交易体验",
  TPmetatraderPowerfulAnalysisToolsText:
    "近 3,000 种指标，24 种分析对象，支持定制专属动态图表，并可以添加指针，助您分析任何预测和价格方向。",
  TPmetatraderPowerfulAnalysisTools: "强大的分析工具",
  TPmetatraderPioneeringTradingSystemText:
    "通过结合不同的市场类型、挂单和止损单，以及使用追踪止损等，无论当前市场状况如何，也可执行任何复杂的交易策略。",
  TPmetatraderPioneeringTradingSystem: "功能超卓的交易系统",
  TPmetatraderMostPopularTradingPlatformText:
    "数百万交易者的首选，先进的技术分 析，灵活的交易系统，为您提供一流 交易体验",
  TPmetatraderMostPopularTradingPlatform: "全球最热门的零售交易平台",
  TPmetatrader5MetaTrader5MobileText:
    "下载 Lay Ray MT5 移动端，随时随地进行货币对、股票和其他证券等金融产品的交易，享受指尖上的环球投资。",
  TPmetatrader5MetaTrader5Mobile: "MetaTrader 5 移动端",
  TPmetatrader5MetaTrader5DesktopVersionText:
    "下载 Lay Ray MT5 桌面版，进行货币对、股票、期货等金融产品的交易；多样化的交易功能，全面的技术分析，复制交易和 EA 自动交易等功能，助您抢占先机。",
  TPmetatrader5MetaTrader5DesktopVersion: "MetaTrader 5 桌面版",
  TPmetatrader5OperatingRoundtheClockText:
    "全天候自动分析行情和执行交易操作，享受每天 24 小时的高效金融交易。 ",
  TPmetatrader5OperatingRoundtheClock: "全天候",
  TPmetatrader5InstantaneousText:
    "即时处理大量货币、股票和其他交易产品的报价，提供精确信号，让您不错失任何机遇。 ",
  TPmetatrader5Instantaneous: "即时性",
  TPmetatrader5IntelligentText:
    "严格遵守编程算法，客观且不间断的大量计算操作能力，甚至可完全替代真人交易。 ",
  TPmetatrader5Intelligent: "智能化",
  TPmetatrader5TradewithEAoMT5Text:
    "为日复一日在环球金融市场操作的交易者，提供最精密的自动化交易。",
  TPmetatrader5TradewithEAoMT5: "MT5 与 EA 智能交易",
  TPmetatrader5CustomizableTradingAlertText:
    "您只需设置触发交易预警的条件，即可通过电子邮件、手机提醒等方式接收重要交易事件预警，无需紧盯屏幕，不错失交易时机。",
  TPmetatrader5CustomizableTradingAlert: "可定制的交易预警",
  TPmetatrader5FundamentalAnalysisText:
    "MT5 的内置工具：财经新闻和经济日历，让您从国际新闻机构直接获取财经新闻及实时报价，让您站在信息的最前沿，使交易操作更及时。",
  TPmetatrader5FundamentalAnalysis: "纵观全局的基础分析",
  TPmetatrader5TechnicalAnalysisText:
    "为您提供整套的分析方式，内含 38 种技术指标和 44 种图形对象，提供全面的市场分析，预测您关注的价格动向。",
  TPmetatrader5TechnicalAnalysis: "窥探未来的技术分析",
  TPmetatrader5QuoteSpecializedChartAnalysisText:
    "提供 21 种时间表，80 多种技术指标和分析工具，可一次性打开 100 个图表，助您识别各种交易品种的走势，并可创建自定义指标。",
  TPmetatrader5QuoteSpecializedChartAnalysis: "专业的图表报价分析",
  TPmetatrader5ComprehensiveTradingSystemText:
    "一个账户支持全部交易订单类型和执行模式，让您轻松应对每一个交易可能，不错失任何买卖机会。",
  TPmetatrader5ComprehensiveTradingSystem: "灵活的交易系统",
  TPmetatrader5CuttingedgeTradingSoftwareText:
    "体验功能强大、工具齐全的一体化金融交易软件，一键投资多个市场，操作丰富的投资品种。",
  TPmetatrader5CuttingedgeTradingSoftware: "强大的交易软件",
  TPmetatrader5Title:
    "融通全球金融市场的多元化交易平台，买卖货币对 、股票、期货等金融产品的明智选择",
  TPVpsFastefficientandreliable: "Fast, efficient and reliable",
  TPVpsVPSHostingforEfficaciousTrading: "VPS Hosting for Efficacious Trading",
  TPVpsVPSHostingforEfficaciousTradingText:
    "Execute your Trading Anytime, Anywhere with Superluminal Responsiveness",
  TPVpsWhatisVPSTrading: "What is VPS Trading?",
  TPVps247365Operation: "24/7/365 Operation",
  TPVps247365OperationText:
    "A VPS trading dedicated server is like a virtual computer running independently 24 hours a day to have 99.99% connection time between the virtual platform and the broker's server, in order for the operation of expert advisors (EA) to run uninterruptedly",
  TPVpsSafeandStable: "Safe and Stable",
  TPVpsSafeandStableText:
    "During periods of severe market fluctuations, VPS can provide you with a more secure and stable trading environment, eliminating obstacles such as computer viruses and power outages.",
  TPVpsEliminateLatency: "Eliminate Latency",
  TPVpsEliminateLatencyText:
    "VPS solution offering traders minimum pings, removing unnecessary delays in the trading process.",
  TPVpsUsingVPStoTradein: "Using VPS to Trade in Lay Ray",
  TPVpsUsingVPStoTradeinText:
    "Experience a safe, stable, and smooth trading environment",
  TPVpsTopServerSpecifications: "Top Server Specifications",
  TPVpsTopServerSpecificationsText:
    "The use of high-speed central processing unit (CPU) and random-access memory (RAM) to ensure the best network connection and execution speed, eliminate latency, and help you to maximize your profits.",
  TPVpsRejectAllTradingObstacles: "Reject All Trading Obstacles",
  TPVpsRejectAllTradingObstaclesText:
    "Connection to the internet 24/7, not affected by unexpected network disruptions, power outages, or system errors,to ensure uninterrupted trading opportunities.",
  TPVpsHardwareSoftwareIsolationandProtection:
    "Hardware/Software Isolation and Protection",
  TPVpsHardwareSoftwareIsolationandProtectionText:
    "Operating System (OS) virtualization isolates software and hardware, effectively prevents cyber attacks, and enhances the security of transactions.",
  TPVpsStartTradingwithVPSNow: "Start Trading with VPS Now",
  TPVpsStartTradingwithVPSNowText1:
    "1.Register a real Lay Ray account with us and make a deposit of more than USD 5,000 within the current month to apply for a free VPS server.",
  TPVpsStartTradingwithVPSNowText2:
    "2.If you already have a real trading account with us and have deposited more than USD 5,000 in the current or last month, you can directly contact our customer service for installation assistance.",
  TPVpsContactUs: "Contact Us",
  TPVpsContactUsText: "You may leave your contact information here, and a Lay Ray representative will contact you within 1 working day.",
  TPVpsFirstName: "First Name",
  TPVpsLastName: "Last Name",
  TPVpsPhoneNumber: "Phone Number",
  TPVpsEmail: "Email",
  TPVpsCountryRegion: "Country / Region",
  TPVpsRiskDisclosure: "Risk Disclosure of High-risk Trading Instruments",
  TPVpsRiskDisclosureText1: "Trading in financial instruments involves high risks due to the fluctuation in the value and prices of the underlying financial instruments. Due to the adverse and unpredictable market movements, large losses exceeding the investor's initial investment could incur within a short period of time. The past performance of a financial instrument is not an indication of its future performance.",
  TPVpsRiskDisclosureText2: "Please make sure you read and fully understand the trading risks of the respective financial instrument before engaging in any trade with us. You should seek independent professional advice if you do not understand the risks disclosed by us herein.",
  TPVpsSubmit: "Submit",
  TPVps: "InTrade",
  TPVps: "InTrade",
  TPVpsSubmit: "立即提交",
  TPVpsRiskDisclosureText2: "在与我们进行任何交易之前，请确保您完全了解使用相应金融工具进行交易的风险，如果您不了解此处说明的风险，则应寻求独立专业的意见。",
  TPVpsRiskDisclosureText1: "由于基础金融工具的价值和价格会有剧烈变动，股票、证券、期货、差价合约和其他金融产品交易涉及高风险，可能会在短时间内发生超过您的初始投资的大额亏损。过去的投资表现并不代表其未来的表现。",
  TPVpsRiskDisclosure: "高风险交易产品风险披露",
  TPVpsCountryRegion: "国家 / 地区",
  TPVpsEmail: "邮箱",
  TPVpsPhoneNumber: "电话",
  TPVpsLastName: "名",
  TPVpsFirstName: "姓",
  TPVpsContactUsText:"留下您的联系方式，我们的专属顾问会在 1 个工作日内和您联系",
  TPVpsContactUs:"联系我们",
  TPVpsStartTradingwithVPSNowText2:
    "2.如您已有我们真实交易账户，并在当月或上月存款 5,000 美元以上，可直接联系我们的客服获取安装协助。",
  TPVpsStartTradingwithVPSNowText1:
    "1.注册 Lay Ray 的真实交易账户，并在当月存款 5,000 美元以上，即可免费申请 VPS 服务。",
  TPVpsStartTradingwithVPSNow: "即刻开启 VPS 交易体验",
  TPVpsHardwareSoftwareIsolationandProtectionText:
    "操作系统虚拟化技术实现软件和硬件的隔离，有效防止黑客攻击，大大增强交易的安全性。",
  TPVpsHardwareSoftwareIsolationandProtection: "软件硬件安全隔离",
  TPVpsRejectAllTradingObstaclesText:
    "7*24 小时永远连接到互联网，不受网络意外中断、停电或系统错误影响，确保智能交易不间断，把握任何交易机会。",
  TPVpsRejectAllTradingObstacles: "拒绝一切交易障碍",
  TPVpsTopServerSpecificationsText:
    "采用高速中央处理器（CPU）和随机存取存储器（RAM），确保最佳的网络连接及执行速度，消除延迟，助您获得最大利润。",
  TPVpsTopServerSpecifications: "顶级服务器规格",
  TPVpsUsingVPStoTradeinText: "体验安全稳定及流畅的交易环境",
  TPVpsUsingVPStoTradein: "在 Lay Ray 使用 VPS 交易",
  TPVpsEliminateLatencyText:
    "为交易者提供最小的 ping 值，消除了交易过程中不必要的延迟。",
  TPVpsEliminateLatency: "消除延迟",
  TPVpsSafeandStableText:
    "在市场剧烈波动期间，VPS 可为您提供更安全稳定的交易环境，排除电脑病毒及断电等障碍。",
  TPVpsSafeandStable: "安全稳定",
  TPVps247365OperationText:
    "VPS 交易专用服务器相当于一台全天候 24 小时独立运行的虚拟电脑，让虚拟平台与交易商服务器的连接时间能达到 99.99%，使智能交易（EA）的操作不间断运行。",
  TPVps247365Operation: "全天候运行",
  TPVpsWhatisVPSTrading: "什么是 VPS 交易 ？",
  TPVpsVPSHostingforEfficaciousTradingText:
    "实时在线，光速响应，随时随地获取最佳交易执行",
  TPVpsVPSHostingforEfficaciousTrading: "VPS 交易体验",
  TPVpsFastefficientandreliable: "快速、高效、可靠的",
};
